import Honeybadger from '@honeybadger-io/js';

const ignoredUserAgents = [
  'Baiduspider',
  'Googlebot',
  'AdsBot-Google',
];

// Chrome settings to block third-party cookies causes this, want to ignore
const chromeCookieMonster = "Failed to read the 'sessionStorage' property from" +
  " 'Window': Access is denied for this document";

const ignoredMessages = [
  // old versions of Firefox when changing pages before scripts load
  'Error loading script',
  // Android LG browser
  '$$LGBrowser_ReadModeBridge',
  // mobile Safari browser
  "Can't find variable: $B",
  "Can't find variable: appAPI",
  // mystery plugin on Safari
  "Unexpected identifier 'script'",
  // Phonegap issue
  'inappbrowser',
  // adware
  'cts.adssend.net',
  'FO.$',
  // plugin (maybe flash) issue we don't care about.
  'NPObject',
  'SecurityError: DOM Exception 18', // IOS user blocking cookies
  'frames.ezLinkPreviewIFRAME', // looks like a Chrome plugin
  'Permission denied to access property "toString"', // Firefox + flash bug
  chromeCookieMonster,
  '__gCrWeb', // Chrome for IOS auto-fill bug
  'og_head', // IOS mobile browser issue
  // median js issue
  "Unable to get property 'setOnChatStart' of undefined or null reference",
  "TrackJS Caught: Can't execute code from a freed script",
  "ReferenceError: 'fidoCallback' is undefined", // user-installed software
  "Can't find variable: __scanAndKill",
];

const ignoredStacks = [
  'mini-profiler',
  'assets.zendesk.com', // zendesk chat
  'pendo',
  'safari-extension',
  'user-script',
  'zopim.com', // live media chat
  'kingtopdeals.com',
  'chrome-extension',
  'bam.nr-data.net', // spamalicious
  '_watcherReady', // Avast Antivirus
  'avg.js', // AVG Antivirus
  'blockpage.cgi', // IE filtering service
  'httpErrorPagesScripts', // IE security setting, or maybe virus
  'KW__injectedScriptMin', // dashlane autofill extension bug
  // who knows? (generic 3rd party browser error)
  'unknown:1',
  'unknown:0',
  'undefined:0',
  'crweb.js', // Chrome bug on IOS
  // Injected popup ads/viruses
  'anyplacetrivial.com',
  'terasgames.com',
  'shpzlist',
  'akamaihd.net',
  'www.tr553.com',
  'utop.it',
  'qwiklinx.net',
  'x7bvtupe.com',
  'd2a8a4q9.ssl.hwcdn.net',
  'foxydeal.com',
  'srdrvp.com',
  'tlscdn.com',
  'secure.loadfree.org',
  'bestpriceninja.com',
  'ipav.xyz',
  'eshopcomp.com',
  'easyshoppermac.com',
  'leadin.com',
  'kaspersky-labs.com',
];

const ignoredUrls = [
  'file://',
];

Honeybadger.configure({
  apiKey: window.gon.honeybadgerAPIFrontendKey,
  enableUncaught: true,
  environment: window.gon.railsEnv,
  maxErrors: 3,
  revision: window.gon.revision,
});

Honeybadger.setContext({
  applicationJsLoaded: 'nope',
  applicationLoadStarted: 'nope',
  onPageSince: new Date().toString(),
  userId: window.gon.userTraits && window.gon.userTraits.id,
});

Honeybadger.beforeNotify((notice) => {
  if (!notice) { return true; }

  if (_ignoredMessage(notice.message)) { return false; }
  if (_ignoredStack(notice.stack)) { return false; }
  if (_ignoredUrl(notice.url)) { return false; }

  const { userAgent } = window.navigator;

  if (_ignoredUserAgent(userAgent)) { return false; }

  return true;
});

// private

function _ignoredUserAgent(userAgent: string): boolean {
  if (!userAgent) { return false; }

  return ignoredUserAgents.some((ignoredUserAgent) => {
    return userAgent.includes(ignoredUserAgent);
  });
}

function _ignoredMessage(message: string): boolean {
  return ignoredMessages.some((ignoredMessage) => {
    return message.includes(ignoredMessage);
  });
}

function _ignoredStack(stack: string): boolean {
  if (!stack) { return false; }

  return ignoredStacks.some((ignoredStack) => {
    return stack.includes(ignoredStack);
  });
}

function _ignoredUrl(url: string | undefined): boolean {
  if (!url) { return false; }

  return ignoredUrls.some((ignoredUrl) => {
    return url.includes(ignoredUrl);
  });
}

export default Honeybadger;
