// @ts-strict-ignore
import {
  CORE_COLUMNS,
  STATUS_COLUMNS,
  TRACKER_FIELDS_COLUMN,
} from 'src/responses/config/headers';

function headerMappings(
  campaign: Partial<Campaign>,
  statusGroup = 'active',
): Responses.TableHeader[] {
  let headers: Responses.TableHeader[] = [];
  const statusColumns = STATUS_COLUMNS[campaign.type];
  const coreColumns = CORE_COLUMNS[campaign.type];

  headers = headers.concat(statusColumns[statusGroup], coreColumns);
  const [trackerFieldNumber] = campaign.trackerFieldNumbers;

  if (trackerFieldNumber) {
    const sortBy = { sortBy: `field_number_${trackerFieldNumber}` };

    headers = headers.concat({ ...TRACKER_FIELDS_COLUMN, ...sortBy });
  }

  return headers;
}

export { headerMappings };
