// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';
import { FrameElement } from '@hotwired/turbo';

import { FORM_SET_INTERVAL, FORM_SET_TIMEOUT } from 'src/form_sets/config/timing';
import { triggerEvent } from 'src/helpers/event';
import { fetchGet } from 'src/helpers/fetch';
import { findEl, findEls } from 'src/helpers/finders';
import Location from 'src/helpers/location';
import notify from 'src/helpers/notify';

class FormSetDownloadController extends Controller {
  static values = { formSetId: Number };

  static targets = [
    'progressBar',
    'loadingHeader',
    'loadingBody',
    'errorHeader',
    'errorBody',
    'errorMessage',
  ];

  static errorMessage = 'We are unable to download your file right now. ' +
    'Please try again, or contact support.';

  static timeoutMessage = 'There was an error processing your request. ' +
    'Try selecting a smaller number of submissions.';

  formSetIdValue: number;
  progressBarTarget: HTMLDivElement;
  loadingHeaderTarget: HTMLDivElement;
  loadingBodyTarget: HTMLDivElement;
  errorHeaderTarget: HTMLDivElement;
  errorBodyTarget: HTMLDivElement;
  errorMessageTarget: HTMLParagraphElement;
  elapsedTime: number;

  connect(): void {
    this.elapsedTime = 0;
    this.progressBarTarget.setAttribute('style', 'width: 0%;');
    this.poll();
  }

  poll(): void {
    const formSetUrl = `/api/form_sets/${this.formSetIdValue}`;

    setTimeout(async () => {
      try {
        const { data: formSet }: { data: FormSet } = await fetchGet(formSetUrl);

        checkFormSet(formSet, this);
      } catch (error) {
        notify(error as Error);
        handleError(FormSetDownloadController.errorMessage, this);
      }
    }, FORM_SET_INTERVAL);
  }
}

// private

function checkFormSet(
  formSet: FormSet,
  controller: FormSetDownloadController,
): void {
  const { errorMessage, timeoutMessage } = FormSetDownloadController;

  if (formSet.status === 'completed') {
    handleComplete(formSet, controller);
  } else if (formSet.status === 'error') {
    handleError(errorMessage, controller);
  } else if (controller.elapsedTime >= FORM_SET_TIMEOUT) {
    handleError(timeoutMessage, controller);
  } else {
    updateProgress(formSet, controller);
    controller.poll();
  }
}

function handleComplete(
  formSet: FormSet,
  controller: FormSetDownloadController,
): void {
  if (formSet.fileDownloadUrl) {
    updateProgress(formSet, controller);

    setTimeout(() => {
      Location.downloadFileAt(formSet.fileDownloadUrl);
      closeModal();
      resetResponsesRows();
    }, 500);
  } else {
    notifyNoFileUrl(formSet);
    handleError(FormSetDownloadController.errorMessage, controller);
  }
}

function closeModal(): void {
  const modalFrame: FrameElement =
    findEl(document, 'turbo-frame', '#dialog-container');

  triggerEvent(modalFrame, 'close');
}

function resetResponsesRows(): void {
  const responsesCheckboxes: HTMLInputElement[] =
    findEls(document, 'input', '.responses__checkbox');

  for (const checkbox of responsesCheckboxes) {
    if (checkbox.checked) {
      triggerEvent(checkbox, 'click');
    }
  }
}

function handleError(message: string, controller: FormSetDownloadController): void {
  controller.errorMessageTarget.innerText = message;
  controller.loadingHeaderTarget.classList.add('hide');
  controller.loadingBodyTarget.classList.add('hide');
  controller.errorHeaderTarget.classList.remove('hide');
  controller.errorBodyTarget.classList.remove('hide');
}

function notifyNoFileUrl(formSet: FormSet): void {
  notify(
    'No file url but formSet is completed',
    { formSetId: formSet.id, userId: window.gon.userTraits.id },
  );
}

function updateProgress(
  formSet: FormSet,
  controller: FormSetDownloadController,
): void {
  controller.elapsedTime += FORM_SET_INTERVAL;
  const percentageString = calculateProgress(formSet, controller.elapsedTime);

  controller.progressBarTarget.setAttribute(
    'style',
    `width: ${percentageString}%;`,
  );
}

function calculateProgress(formSet: FormSet, elapsedTime: number): string {
  let progress = 0;

  if (formSet.status === 'completed') {
    progress = 100;
  } else if (elapsedTime > 0) {
    progress = 100 * ((elapsedTime / FORM_SET_TIMEOUT) ** (1 / 4));
  }

  return progress.toFixed(2);
}

export default FormSetDownloadController;
