import { ActionEvent, Controller } from '@hotwired/stimulus';

import { findEl } from 'src/helpers/finders';

class DialogParamsController extends Controller {
  static targets = ['input', 'buttonToDialog'];

  inputTargets!: HTMLInputElement[];
  buttonToDialogTarget!: HTMLButtonElement;

  connect(): void {
    this.setButtonState();
  }

  setButtonState(): void {
    const requiredFieldsHaveValues = this.inputTargets.every((input) => {
      if (input.required) {
        return Boolean(input.value);
      }

      return true;
    });

    this.buttonToDialogTarget.disabled = !requiredFieldsHaveValues;
  }

  setHiddenField(event: ActionEvent & { target: HTMLInputElement }): void {
    const hiddenField =
      findEl(document, 'input', `[name='${event.params.input}']`);

    hiddenField.value = event.target.value;

    this.setButtonState();
  }
}

export default DialogParamsController;
