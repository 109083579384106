// @ts-strict-ignore
import keyBy from 'lodash/keyBy';
import React, { ReactNode } from 'react';

import { BoundAllFieldsLoaded, BoundUpdateDocEditor }
  from 'src/chux/doc_editor/single_store';
import { BoundGetFields, BoundUpdateFields } from 'src/chux/editable_fields/store';
import FormulaDecorator from 'src/decorators/formula';
import NumberPrecisionDropdown
  from 'src/doc_editor/fields/components/common/number_precision_dropdown';
import FormulaValidator, { FormulaErrors }
  from 'src/doc_editor/fields/helpers/formula_validator';
import FormulaDependencyUpdater from 'src/doc_editor/formula_dependency_updater';
import { events, subscribe } from 'src/helpers/pub_sub';

type Props = {
  allFieldsLoaded: BoundAllFieldsLoaded;
  getFields: BoundGetFields;
  steps: Step[] | null;
  mode: 'number' | 'time';
  number: number;
  updateDocEditor: BoundUpdateDocEditor;
  updateFields: BoundUpdateFields;
  content: string;
  errors: string[];
  precision: number;
};

type State = {
  formulaString: string;
};

class FormulaContextMenu extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.updateFormula = this.updateFormula.bind(this);
    this.updateMode = this.updateMode.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.updateTimeInput = this.updateTimeInput.bind(this);
    this.state = { formulaString: this.props.content };
    this.validateFormula = this.validateFormula.bind(this);
    setSubscribedEvents(this);
  }

  componentDidMount(): void {
    this.validateFormula();
  }

  componentDidUpdate(): void {
    this.highlightFieldsFromFormula();
  }

  render(): ReactNode {
    return (
      <div>
        {this.modeDropdown()}
        <div>
          {this.formulaInput()}
          {this.renderValidationError()}
        </div>
        {this.numberPrecisionDropdown()}
      </div>
    );
  }

  formulaInput(): JSX.Element {
    const { mode } = this.props;

    if (mode === 'number') {
      return this.numberFormulaInput();
    }

    return this.timeFormulaInput();
  }

  numberFormulaInput(): JSX.Element {
    return (
      <div className='wb-u-margin-b-2'>
        <label>
          Formula:
          <br />
          <span className='pull-left text-lg'>=&nbsp;</span>
          <input
            type='text'
            value={this.state.formulaString}
            onBlur={this.handleBlur}
            onChange={this.updateFormula}
            onFocus={this.handleFocus}
          />
        </label>
      </div>
    );
  }

  timeFormulaInput(): JSX.Element {
    const { formulaString } = this.state;

    return (
      <div className='time-formula-inputs wb-u-margin-b-2'>
        <label>
          Start:
          <input
            name='start_time'
            type='text'
            value={startTimeString(formulaString)}
            onBlur={this.handleBlur}
            onChange={this.updateTimeInput}
            onFocus={this.handleFocus}
          />
        </label>
        <label>
          End:
          <input
            name='end_time'
            type='text'
            value={endTimeString(formulaString)}
            onBlur={this.handleBlur}
            onChange={this.updateTimeInput}
            onFocus={this.handleFocus}
          />
        </label>
      </div>
    );
  }

  renderValidationError(): JSX.Element | boolean {
    const { errors } = this.props;

    if (errors === undefined || errors.length === 0) { return false; }

    return <div className='alert-danger'>{errors.map(wrappedError)}</div>;
  }

  updateTimeInput(event: React.ChangeEvent<HTMLInputElement>): void {
    const { formulaString } = this.state;
    const { name, value } = event.target;
    let content = '';

    if (name === 'start_time') {
      content = formulaStringFromTimes(value, endTimeString(formulaString));
    } else if (name === 'end_time') {
      content = formulaStringFromTimes(startTimeString(formulaString), value);
    }

    this.updateFormulaString(content);
    this.validateFormula();
  }

  updateFormula(event: React.ChangeEvent<HTMLInputElement>): void {
    const { value } = event.target;
    const { number, updateFields, getFields } = this.props;

    this.updateFormulaString(value);

    const dependentFieldsPayload =
      FormulaDependencyUpdater.updateDependencies(number, value, getFields());

    updateFields(dependentFieldsPayload);

    this.validateFormula();
  }

  validateFormula(): void {
    const {
      allFieldsLoaded,
      getFields,
      steps,
      number,
      updateFields,
    } = this.props;

    if (allFieldsLoaded()) {
      const fieldsByNumber = getFields();
      const formulaField = fieldsByNumber[number];
      const stepsById = keyBy(steps || [], 'id');
      const formulaErrors: FormulaErrors = FormulaValidator
        .validate(formulaField, fieldsByNumber, stepsById);

      updateFields(formulaErrors);
    }
  }

  updateFormulaString(content: string): void {
    const { number, updateFields } = this.props;

    this.setState({ formulaString: content });
    updateFields({ content, number });
  }

  handleFocus(): void {
    this.displayFieldMedallions();
    this.highlightFieldsFromFormula();
  }

  handleBlur(): void {
    this.hideFieldMedallions();
    this.resetHighlightedFields();
  }

  highlightFieldsFromFormula(): void {
    const { formulaString } = this.state;
    const formula = new FormulaDecorator(formulaString);
    const numbers = formula.getFieldNumbers();

    this.props.updateDocEditor({ highlightedFields: numbers });
  }

  resetHighlightedFields(): void {
    this.props.updateDocEditor({ highlightedFields: [] });
  }

  displayFieldMedallions(): void {
    const { mode, number, updateDocEditor } = this.props;
    const payload = {
      activeFormulaMode: mode,
      activeFormulaNumber: number,
      showMedallions: true,
    };

    updateDocEditor(payload);
  }

  hideFieldMedallions(): void {
    const payload = {
      activeFormulaMode: null,
      activeFormulaNumber: null,
      showMedallions: false,
    };

    this.props.updateDocEditor(payload);
  }

  modeDropdown(): JSX.Element {
    return (
      <div>
        <label>
          Calculation type:
          <select
            name='mode'
            value={this.props.mode}
            onChange={this.updateMode}
          >
            <option value='number'>Number</option>
            <option value='time'>Time duration</option>
          </select>
        </label>
      </div>
    );
  }

  updateMode(event): void {
    const { value } = event.target;
    const { number, updateFields } = this.props;

    updateFields({ content: '', mode: value, number });
    this.setState({ formulaString: '' });
  }

  numberPrecisionDropdown(): JSX.Element {
    const { number, precision, updateFields } = this.props;

    return (
      <NumberPrecisionDropdown
        number={number}
        precision={precision}
        updateFields={updateFields}
      />
    );
  }
}

// private

function setSubscribedEvents(contextMenu: FormulaContextMenu): void {
  subscribe(events.STEP_ASSIGNMENT_CHANGED, contextMenu.validateFormula);
  subscribe(events.FIELD_CHANGED, contextMenu.validateFormula);
  subscribe(events.FIELD_REMOVED, contextMenu.validateFormula);
}

function wrappedError(error: string, index: number): JSX.Element {
  return <div key={index}>{error}</div>;
}

function startTimeString(formulaString: string): string {
  return formulaString ? formulaString.split('-')[1] : '';
}

function endTimeString(formulaString: string): string {
  return formulaString ? formulaString.split('-')[0] : '';
}

function formulaStringFromTimes(startTime: string, endTime: string): string {
  return startTime === '' && endTime === '' ? '' : `${endTime}-${startTime}`;
}

export default FormulaContextMenu;
export type { Props };
