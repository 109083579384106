// @ts-strict-ignore
import React from 'react';
import ReactDOM from 'react-dom';

import ContextMenuWrapper from 'src/doc_editor/context_menu_wrapper';
import EditableLabel from 'src/doc_editor/fields/components/common/editable_label';
import $template from 'src/helpers/dollar_template';
import { findEl } from 'src/helpers/finders';

class StaticTextContextMenuWrapper extends ContextMenuWrapper {
  constructor(opts) {
    super(opts);

    this.initializeEditableLink(opts);
  }

  get linkContainer(): HTMLElement {
    return findEl(this.element, 'div', '.link-container');
  }

  initializeEditableLink(opts): void {
    const $body = $template('link-template');

    this.$element.find('.context-menu-body').append($body);

    this.renderEditableLink(opts);
  }

  renderEditableLink(opts): void {
    const linkOpts = {
      name: 'link',
      update(attrs): void {
        if (attrs.link) { attrs.link = attrs.link.replace(' ', '%20'); }

        opts.updateFields({ number: opts.number, ...attrs });
      },
      value: opts.link,
    };

    ReactDOM.render(
      <React.StrictMode>
        <EditableLabel {...linkOpts} />
      </React.StrictMode>
      ,
      this.linkContainer,
    );
  }
}

export default StaticTextContextMenuWrapper;
