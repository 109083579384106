// @ts-strict-ignore
import React, { ReactNode } from 'react';

import { UpdateFeedbackServer } from 'src/feedbacks/action_creators';
import { FEEDBACK_SELECTIONS } from 'src/feedbacks/config/feedback_selections';
import notify from 'src/helpers/notify';

type Props = {
  updateFeedbackServer: UpdateFeedbackServer;
  formRequestId: string;
  token: string;
};

type Feedback = {
  note: string;
  easy: boolean;
  ecofriendly: boolean;
  efficient: boolean;
  other: boolean;
};

type State = {
  feedback: Feedback;
  isSubmitting: boolean;
  isSubmitted: boolean;
  noteValue: string;
};

class FeedbackForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.handleSelection = this.handleSelection.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      feedback: {
        easy: false,
        ecofriendly: false,
        efficient: false,
        note: '',
        other: false,
      },
      isSubmitted: false,
      isSubmitting: false,
      noteValue: '',
    };
  }

  render(): ReactNode {
    const { isSubmitted } = this.state;

    return (
      <div>
        { isSubmitted ? feedbackConfirmation() : this.feedbackForm() }
      </div>
    );
  }

  handleSelection(event): void {
    const { feedback } = this.state;
    const {
      formRequestId,
      token,
      updateFeedbackServer,
    } = this.props;

    const key = event.target.getAttribute('data-key');
    const newFeedback = { ...feedback, [key]: !feedback[key] };

    this.setState({ feedback: newFeedback });
    updateFeedbackServer(newFeedback, { formRequestId, token });
  }

  handleSubmit(event: React.MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    const { feedback } = this.state;
    const {
      formRequestId,
      token,
      updateFeedbackServer,
    } = this.props;
    const newFeedback = { ...feedback, note: this.state.noteValue };

    this.setState({ isSubmitting: true });
    updateFeedbackServer(newFeedback, { formRequestId, token }).then(() => {
      this.setState({ isSubmitted: true, isSubmitting: false });
    }, notify);
  }

  handleChange(event: React.ChangeEvent<HTMLTextAreaElement>): void {
    this.setState({ noteValue: event.target.value });
  }

  feedbackForm(): JSX.Element {
    return (
      <React.Fragment>
        <h2 className='wb-heading-5 wb-u-marign-b-2'>Rate your experience</h2>
        <p className='wb-text wb-u-margin-b-4'>Select all that apply.</p>
        <div className='wb-l-row wb-l-row--wrap wb-u-margin-b-4'>
          {this.feedbackCards()}
        </div>
        <form>
          <label className='wb-u-margin-b-0' htmlFor='feedback-note'>
            <h2 className='wb-heading-5 wb-u-margin-b-2'>Suggestion Box</h2>
            <div className='wb-text wb-u-margin-b-4'>
              Feedback is sent to the Informed K12 Support team and may be shared
              with your District. Please do not include private information or
              messages intended for your District.
            </div>
          </label>
          {this.feedbackNotes()}
        </form>
      </React.Fragment>
    );
  }

  feedbackCards(): JSX.Element[] {
    return Object.keys(FEEDBACK_SELECTIONS).map((key) => {
      const selection = FEEDBACK_SELECTIONS[key];
      const isSelected = this.state.feedback[key];
      const buttonClass = [
        'create-success-option',
        `${isSelected ? 'active' : ''}`,
      ].join(' ');

      return (
        <button
          key={key}
          className={buttonClass}
          data-key={key}
          type='button'
          onClick={this.handleSelection}
        >
          <img
            alt=''
            className='create-success-option__image'
            src={selection.image}
          />
          <span
            className='create-success-option__text'
          >
            {selection.text}
          </span>
        </button>
      );
    });
  }

  feedbackNotes(): JSX.Element {
    return (
      <React.Fragment>
        <div className='form-group'>
          <textarea
            className='form-control wb-textarea'
            id='feedback-note'
            name='note'
            rows={5}
            value={this.state.noteValue}
            onChange={this.handleChange}
          />
        </div>
        <div className='clearfix form-group'>
          {this.feedbackSubmitButton()}
        </div>
      </React.Fragment>
    );
  }

  feedbackSubmitButton(): JSX.Element {
    const { isSubmitting } = this.state;

    return (
      <button
        className='wb-button pull-right'
        disabled={!this.state.noteValue.trim() || isSubmitting}
        type='submit'
        onClick={this.handleSubmit}
      >
        Send
      </button>
    );
  }
}

// private

function feedbackConfirmation(): JSX.Element {
  return (
    <h2 className='wb-heading-4'>
      Your suggestion has been sent
    </h2>
  );
}

export default FeedbackForm;
export type { Feedback, Props };
