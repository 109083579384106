// @ts-strict-ignore
import { connect } from 'react-redux';

import ResponsesIndex from 'src/responses/components/index';

function mapStateToProps(state) {
  const {
    responses,
    meta: { pagination, queryParams, isFetching },
  } = state.responses;
  const { page, pageSize } = queryParams;

  return {
    isFetching: isFetching !== false,
    pagination: { ...pagination, page, pageSize },
    queryParams,
    responses: responses || [],
  };
}

export default connect(mapStateToProps)(ResponsesIndex);
