// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';

import { snakeCase, snakeCaseKeys } from 'src/helpers/json';

class ResponsesFilterController extends Controller {
  static targets = ['checkbox', 'input'];

  checkboxTargets: HTMLInputElement[];
  checkboxTarget: HTMLInputElement;
  inputTarget: HTMLInputElement;

  applyCheckboxFilter(): void {
    const values: string[] = [];

    this.checkboxTargets.forEach((checkbox) => {
      if (checkbox.checked) {
        values.push(checkbox.value);
      }
    });

    this.addFilter(this.checkboxTarget.name, values);
  }

  applyFilter(event: { params: {
    key: number | string;
    value: string[] | object;
  }; }): void {
    const { key, value } = event.params;

    this.addFilter(key, value);
  }

  applyTextFilter(event: { params: {
    key: number | string;
    currentValue?: number | string;
  }; }): void {
    let currentValue: string[] = [];

    if (event.params.currentValue) {
      currentValue = event.params.currentValue.toString().split(',');
    }

    currentValue.push(this.inputTarget.value);

    this.addFilter(event.params.key, currentValue);
  }

  addFilter(filterKey: number | string, value: string[] | object): void {
    const url = new URL(window.location.href);
    const key = snakeCase(filterKey.toString());

    let filtersParam = JSON.parse(url.searchParams.get('filters')) as object;

    if (Array.isArray(value) && !value.length) {
      delete filtersParam[key];
    } else {
      filtersParam = { ...filtersParam, [key]: value };
    }

    url.searchParams.set('filters', JSON.stringify(filtersParam));
    url.searchParams.set('page', '1');

    Turbo.visit(url.toString());
  }

  clearFilter(event: {
    params: {
      key: number | string;
      currentValue: number | string;
    };
  }): void {
    const url = new URL(window.location.href);
    const key = snakeCase(event.params.key.toString());

    const filtersParam = JSON.parse(url.searchParams.get('filters')) as object;
    const filterValue = filtersParam[key];
    if (Array.isArray(filterValue) && filterValue.length > 1) {
      filtersParam[key] = filterValue
        .filter((value) => { return value !== event.params.currentValue; });
    } else {
      delete filtersParam[key];
    }

    url.searchParams.set('filters', JSON.stringify(filtersParam));
    url.searchParams.set('page', '1');

    Turbo.visit(url.toString());
  }

  clearAllFilters(): void {
    const url = new URL(window.location.href);
    const filtersParam = JSON.parse(url.searchParams.get('filters'));
    const newFilters = snakeCaseKeys({ statusGroup: filtersParam.status_group });

    url.searchParams.set('filters', JSON.stringify(newFilters));
    url.searchParams.set('page', '1');

    Turbo.visit(url.toString());
  }
}

export default ResponsesFilterController;
