import FieldModel from 'src/doc_editor/fields/models/field';

const Dropdown = FieldModel.extend({
  defaults: {
    ...FieldModel.prototype.defaults,
    allowedInNumberFormula: true,
    required: false,
    sortDropdownChoices: false,
  },

  validate() {
    const dropdownId = this.get('dropdownId');
    const content = this.get('content');

    if (!dropdownId && !content) {
      return ['No choices have been entered.'];
    }
    return null;
  },
});

export default Dropdown;
