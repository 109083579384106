import React from 'react';

import iconClasses from 'src/helpers/icon';

type IconName =
  'archive' |
  'arrowLeft' |
  'arrowRight' |
  'ban' |
  'caretDown' |
  'caretLeft' |
  'caretRight' |
  'caretUp' |
  'check' |
  'checkCircle' |
  'chevronDown' |
  'chevronUp' |
  'circleNotch' |
  'clockO' |
  'close' |
  'download' |
  'ellipsisHorizontal' |
  'ellipsisVertical' |
  'exclamationCircle' |
  'exclamationTriangle' |
  'fileText' |
  'folder' |
  'heart' |
  'infoCircle' |
  'paperclip' |
  'pencil' |
  'plus' |
  'refresh' |
  'repeat' |
  'reply' |
  'rocket' |
  'search' |
  'send' |
  'share' |
  'shareAlt' |
  'spinner' |
  'times' |
  'timesCircle' |
  'trash' |
  'userPlus' |
  'warning';

type Props = {
  iconClassName?: string;
  name: IconName;
  size?: string;
};

function Icon(props: Props): JSX.Element {
  const { name, size = '', iconClassName = '' } = props;
  const iconName = iconClasses(name);
  const iconSize = size && `fa-${size}`;

  return (
    <i
      aria-hidden='true'
      className={`${iconName} ${iconSize} ${iconClassName}`}
    />
  );
}

export default Icon;
export type { IconName };
