// @ts-strict-ignore
import { ActionEvent, Controller } from '@hotwired/stimulus';
import zip from 'lodash/zip';
import React from 'react';
import ReactDOM from 'react-dom';

import { findEls } from 'src/helpers/finders';
import { hide, show } from 'src/helpers/visibility';
import StepModel from 'src/step_adder/models/step';
import StepAdvancedOptionsRow from 'src/step_adder/step_advanced_options_row';
import StepSelectionsRow from 'src/step_adder/step_selections_row';

type ChangeStepTypeActionEvent = Omit<ActionEvent, 'params'> & {
  params: { clientType: ClientStepType; stepCid: number };
};

class StepRowController extends Controller {
  static targets = [
    'approverListAdderSection',
    'approverListContainer',
    'approverListRecipients',
    'approverListSelect',
    'approverListStepInput',
    'bulkRecipientEmails',
    'bulkRecipientNames',
    'hiddenStepTypeInput',
    'newRecipientRowTemplate',
    'promptStepInput',
    'recipientAdderSection',
    'recipientInput',
    'recipientsContainer',
    'recipientStepInput',
    'removeRecipientButton',
    'showAdvancedOptionsButton',
    'showStepSelectionsButton',
    'skippable',
    'specialApprover',
    'stepAdvancedOptions',
    'stepLabel',
    'stepSelections',
  ];

  static values = {
    step: Object,
  };

  hasApproverListStepInputTarget: boolean;

  approverListAdderSectionTarget: HTMLDivElement;
  approverListContainerTarget: HTMLTableSectionElement;
  approverListRecipientsTarget: HTMLDivElement;
  approverListSelectTarget: HTMLSelectElement;
  approverListStepInputTarget: HTMLInputElement;
  bulkRecipientEmailsTarget: HTMLTextAreaElement;
  bulkRecipientNamesTarget: HTMLTextAreaElement;
  hiddenStepTypeInputTarget: HTMLInputElement;
  newRecipientRowTemplateTarget: HTMLTemplateElement;
  promptStepInputTarget: HTMLInputElement;
  recipientAdderSectionTarget: HTMLDivElement;
  recipientsContainerTarget: HTMLTableSectionElement;
  recipientInputTargets: HTMLTableRowElement[];
  recipientStepInputTarget: HTMLInputElement;
  removeRecipientButtonTargets: HTMLButtonElement[];
  showAdvancedOptionsButtonTarget: HTMLButtonElement;
  showStepSelectionsButtonTarget: HTMLButtonElement;
  skippableTarget: HTMLInputElement;
  specialApproverTarget: HTMLInputElement;
  stepAdvancedOptionsTarget: HTMLDivElement;
  stepLabelTarget: HTMLInputElement;
  stepSelectionsTarget: HTMLDivElement;

  stepValue: StepModel;

  connect(): void {
    const step = this.stepValue;

    this.renderAdvancedOptions();

    this.changeStepTypeForStep(step.cid, 'RecipientStep');
  }

  renderStepSelections(): void {
    const stepType = this.stepType();
    const skippable = this.skippableTarget.checked;
    const specialApprover = this.specialApproverTarget.checked;
    const stepLabel = this.stepLabelTarget.value;

    ReactDOM.render(
      <React.StrictMode>
        <StepSelectionsRow
          approverListContainerRows={this.approverListContainerTarget.rows}
          approverListId={this.approverListSelectTarget.value}
          recipientsContainerRows={this.recipientsContainerTarget.rows}
          skippable={skippable}
          specialApprover={specialApprover}
          stepLabel={stepLabel}
          stepType={stepType}
        />
      </React.StrictMode>,
      this.stepSelectionsTarget,
    );

    show(this.stepSelectionsTarget);
    show(this.showAdvancedOptionsButtonTarget);
    hide(this.stepAdvancedOptionsTarget);
    hide(this.showStepSelectionsButtonTarget);
  }

  renderAdvancedOptions(): void {
    ReactDOM.render(
      <React.StrictMode>
        <StepAdvancedOptionsRow step={this.stepValue} />
      </React.StrictMode>,
      this.stepAdvancedOptionsTarget,
    );

    show(this.stepAdvancedOptionsTarget);
    show(this.showStepSelectionsButtonTarget);
    hide(this.stepSelectionsTarget);
    hide(this.showAdvancedOptionsButtonTarget);
  }

  stepType(): {
    clientType: ClientStepType;
    type: StepType;
  } {
    const approverListStepSelected = this.hasApproverListStepInputTarget &&
      this.approverListStepInputTarget.checked;

    if (this.recipientStepInputTarget.checked) {
      return { clientType: 'RecipientStep', type: 'RecipientStep' };
    } else if (approverListStepSelected) {
      return { clientType: 'ApproverListStep', type: 'RecipientStep' };
    } else if (this.promptStepInputTarget.checked) {
      return { clientType: 'PromptStep', type: 'PromptStep' };
    }
    return { clientType: 'RecipientStep', type: 'RecipientStep' };
  }

  bulkAddRecipients(event: ActionEventWithNumberParams): void {
    const stepCid = event.params.stepCid.toString();

    const names = this.bulkRecipientNamesTarget.value.split('\n');
    const emails = this.bulkRecipientEmailsTarget.value.split('\n');

    zip(names, emails).forEach((recipient) => {
      this.addRecipientToStep(stepCid, recipient[0], recipient[1]);
    });

    this.bulkRecipientNamesTarget.value = '';
    this.bulkRecipientEmailsTarget.value = '';
  }

  addRecipient(event: ActionEventWithNumberParams): void {
    this.addRecipientToStep(event.params.stepCid.toString());
  }

  addRecipientToStep(stepCid: string, name = '', email = ''): void {
    const template = this.newRecipientRowTemplateTarget.content.firstElementChild;
    const newRecipientRow = template.cloneNode(true);
    const tbody = this.recipientsContainerTarget;
    tbody.appendChild(newRecipientRow);

    this.updateInputs(stepCid, { email, name });

    if (this.recipientInputTargets.length > 1) {
      this.removeRecipientButtonTargets.forEach((button) => { show(button); });
    } else {
      hide(this.removeRecipientButtonTargets[0]);
    }
  }

  updateInputs(
    stepCid: string,
    { email, name }: { email: string; name: string },
  ): void {
    const tbody = this.recipientsContainerTarget;
    const inputs = findEls(tbody.lastElementChild, 'input', '.wb-input-field');

    inputs[0].value = name;
    inputs[1].value = email;

    const titleId = `recipient-adder-title-label-${stepCid}`;
    const emailId = `recipient-adder-email-label-${stepCid}`;
    inputs[0].setAttribute('aria-labelledby', titleId);
    inputs[1].setAttribute('aria-labelledby', emailId);

    inputs[0].addEventListener('blur', this.blur);
    inputs[1].addEventListener('blur', this.blur);
  }

  blur(event: FocusEvent): void {
    const target = event.target as HTMLInputElement;
    target.value = target.value.trim();
  }

  removeRecipient(event: ActionEvent & DOMEvent): void {
    event.preventDefault();

    event.currentTarget.closest('tr').remove();

    if (this.recipientInputTargets.length === 1) {
      hide(this.removeRecipientButtonTargets[0]);
    }
  }

  changeStepType(event: ChangeStepTypeActionEvent): void {
    const stepCid = event.params.stepCid.toString();
    this.changeStepTypeForStep(stepCid, event.params.clientType);
  }

  changeStepTypeForStep(stepCid: string, clientType: ClientStepType): void {
    this.clearRecipients(clientType);

    this.changeStepTypeSection(stepCid, clientType);

    this.setHiddenTypeInput(clientType);
  }

  changeStepTypeSection(stepCid: string, clientType: ClientStepType): void {
    if (clientType === 'RecipientStep') {
      hide(this.approverListAdderSectionTarget);
      show(this.recipientAdderSectionTarget);
      this.addRecipientToStep(stepCid);
    } else if (clientType === 'ApproverListStep') {
      hide(this.recipientAdderSectionTarget);
      show(this.approverListAdderSectionTarget);
    } else {
      hide(this.approverListAdderSectionTarget);
      hide(this.recipientAdderSectionTarget);
    }
  }

  clearRecipients(clientType: ClientStepType): void {
    this.recipientInputTargets.forEach((row) => { row.remove(); });

    const requireApproverList = clientType === 'ApproverListStep';
    this.clearApproverListRecipients(requireApproverList);
  }

  clearApproverListRecipients(requireApproverList: boolean): void {
    hide(this.approverListRecipientsTarget);

    if (this.approverListSelectTarget.value) {
      this.approverListSelectTarget.value = '';
    }

    this.approverListSelectTarget.required = requireApproverList;
  }

  setHiddenTypeInput(clientType: ClientStepType): void {
    const input = this.hiddenStepTypeInputTarget;

    input.value = clientType === 'PromptStep' ? 'PromptStep' : 'RecipientStep';
  }
}

export default StepRowController;
