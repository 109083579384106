import classNames from 'classnames';
import React from 'react';

type Props = {
  selected: boolean;
};

function StaticText(props: Props): JSX.Element {
  return (
    <div
      className={
        classNames({
          selected: props.selected,
          'text-input static-text-field adjustable-font field-container': true,
        })
      }
      contentEditable
    >
    </div>
  );
}

export default StaticText;
