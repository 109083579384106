// @ts-strict-ignore
import React, { ReactNode } from 'react';

import Icon from 'src/common/components/icon';
import SelectTag from 'src/common/components/select_tag';

type Props = {
  columns: string[];
  number: number;
};

type State = {
  query: string;
};

class PrefillContextMenu extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.updateStateFromInput = this.updateStateFromInput.bind(this);
    this.state = { query: '' };
  }

  render(): ReactNode {
    const { number } = this.props;
    const selectId = `group-data-prefill-${number}`;

    return (
      <div className='col-md-12'>
        <label htmlFor={selectId}>Group data:</label>
        {this.renderSearch()}
        <SelectTag
          id={selectId}
          options={this.getColumnsForDisplay()}
          selectClassName='column_selector'
        />
      </div>
    );
  }

  updateStateFromInput(event: React.ChangeEvent<HTMLInputElement>): void {
    const data = {};

    data[event.target.name] = event.target.value;
    this.setState(data);
  }

  getColumnsForDisplay(): string[] {
    const lowercaseQuery = this.state.query.toLowerCase().trim();

    return this.props.columns.filter((column) => {
      return column.toLowerCase().includes(lowercaseQuery);
    });
  }

  renderSearch(): JSX.Element | boolean {
    if (this.props.columns.length <= 15) { return false; }

    return (
      <div className='input-group column-search'>
        <span className='input-group-addon'>
          <Icon name='search' />
        </span>
        <input
          className='form-control'
          name='query'
          placeholder='Column search'
          type='text'
          value={this.state.query}
          onChange={this.updateStateFromInput}
        />
      </div>
    );
  }
}

export default PrefillContextMenu;
