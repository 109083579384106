import React from 'react';

import DropdownButton from 'src/common/components/dropdown_button';

const CHOICES = [
  { text: 'Selected', value: true },
  { text: 'Not selected', value: false },
];

type Props = {
  filterKey: number | string;
  filterLabel: string;
};

function CheckboxFilter(props: Props): JSX.Element {
  const { filterLabel, filterKey } = props;

  return (
    <DropdownButton
      buttonClassName='btn-link'
      label={filterLabel}
    >
      {checkboxChoices(filterKey)}
    </DropdownButton>
  );
}

// private

function checkboxChoices(filterKey: number | string): JSX.Element[] {
  return CHOICES.map((choice) => {
    return (
      <li key={choice.value.toString()} data-controller='responses-filter'>
        <a
          data-action='responses-filter#applyFilter'
          data-responses-filter-key-param={filterKey}
          data-responses-filter-value-param={choice.value}
          href='#!'
        >
          {choice.text}
        </a>
      </li>
    );
  });
}

export default CheckboxFilter;
