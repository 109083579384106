import classNames from 'classnames';
import React from 'react';

type Props = {
  isSubsetField?: boolean;
  label: string;
  required: boolean;
  selected?: boolean;
};

function Dropdown(props: Props): JSX.Element {
  const { required, isSubsetField, selected, label } = props;

  return (
    <select
      aria-label={label}
      className={
        classNames({
          'requested_dropdown empty field-container adjustable-font': true,
          required,
          selected,
          striped: isSubsetField,
        })
      }
    >
      <option>Select...</option>
    </select>
  );
}

export default Dropdown;
