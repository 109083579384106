// @ts-strict-ignore
import kebabCase from 'lodash/kebabCase';
import React, { ReactNode } from 'react';

import Icon from 'src/common/components/icon';

type Props = {
  headers: Responses.TableHeader[];
  queryParams: ResponsesQueryParams;
};

class HeaderRow extends React.Component<Props, never> {
  render(): ReactNode {
    return (
      <tr>
        {this.checkboxHeader()}
        {this.dataHeaders()}
        <th className='actions'><span className='sr-only'>Actions</span></th>
      </tr>
    );
  }

  checkboxHeader(): JSX.Element {
    return (
      <th
        className='checkboxes'
        data-action='click->responses-selection#toggleAll'
      >
        <label className='responses__checkbox-container'>
          <input
            aria-label='Select All Submissions'
            className='responses__checkbox'
            data-responses-selection-target='checkboxAll'
            type='checkbox'
          />
        </label>
      </th>
    );
  }

  dataHeaders(): JSX.Element[] {
    const { headers, queryParams } = this.props;

    return headers.map((header) => {
      const isSorted = header.sortBy === queryParams.sortBy;

      return (
        <th
          key={header.attribute}
          className={headerClass(header.attribute, header.sortBy, isSorted)}
          data-action='click->responses-index#handleSort'
          data-responses-index-sort-by-param={header.sortBy}
        >
          {header.title}
          {isSorted && this.sortIcon()}
        </th>
      );
    });
  }

  sortIcon(): JSX.Element {
    const { direction } = this.props.queryParams;
    const iconName = direction === 'asc' ? 'caretUp' : 'caretDown';

    return <Icon name={iconName} />;
  }
}

// private

function headerClass(attribute: string, sortBy: string, isSorted: boolean): string {
  const headerAttribute = kebabCase(attribute);

  if (sortBy === undefined) { return headerAttribute; }
  return headerAttribute.concat(isSorted ? ' sortable sorted' : ' sortable');
}

export default HeaderRow;
export type { Props };
