import classNames from 'classnames';
import React from 'react';

type Props = {
  mode: string;
  required: boolean;
  selected: boolean;
};

function Date(props: Props): JSX.Element {
  const { mode, required, selected } = props;

  return (
    <div
      className={
        classNames({
          'field-container': true,
          [mode]: true,
          'requested-field': mode === 'user_input',
          required: mode === 'user_input' && required,
          selected,
        })
      }
    >
      <div className='text-input date-field adjustable-font'>
        mm/dd/yyyy
      </div>
    </div>
  );
}

export default Date;
