// @ts-strict-ignore
import Field from 'src/doc_editor/field';
import $template from 'src/helpers/dollar_template';
import { events, subscribe } from 'src/helpers/pub_sub';

class Prefill extends Field {
  constructor(opts) {
    super(opts);

    this.setText = this.setText.bind(this);

    if (!opts.initCallbacks) { return; }

    this.setText(this.model, this.model.get('content'));
    this.model.on('change:content', this.setText);

    subscribe(events.GROUP_SELECTION_CHANGED, this.showIfGroupHasColumn.bind(this));
  }

  $preview(): JQuery {
    const templates = { preview: 'prefill-field-preview-template' };
    const $previewElement = $template(templates.preview);

    $previewElement.css({
      left: this.$element.position().left,
      top: this.$element.position().top,
    });

    $previewElement.data('column', this.$element.data('column'));
    return $previewElement;
  }

  setText(_model: Backbone.Model, text: string): void {
    this.$element.data('column', text);
  }

  shouldShowContextMenuOnInit(): boolean { return true; }

  showIfGroupHasColumn(groupData: GroupData): void {
    const columns = groupData.dataColumns;
    const validColumnSelected = columns.includes(this.model.get('content'));
    const action = validColumnSelected ? 'show' : 'hide';

    this[action]();
  }
}

export default Prefill;
