// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';

class SchedulerController extends Controller {
  static targets = ['endAtDateField', 'tbdField'];

  endAtDateFieldTarget: HTMLInputElement;
  tbdFieldTarget: HTMLInputElement;

  setEndAtConstraints(event: DOMEvent<HTMLInputElement>): void {
    this.endAtDateFieldTarget.setAttribute('min', event.currentTarget.value);
  }

  toggleEndAtField(): void {
    this.endAtDateFieldTarget.value = '';
    this.endAtDateFieldTarget.disabled = this.tbdFieldTarget.checked;
  }
}

export default SchedulerController;
