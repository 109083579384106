// @ts-strict-ignore
import React from 'react';

import DropdownButton from 'src/common/components/dropdown_button';

type Props = {
  currentFilterValues: string[] | undefined;
  filterChoices: string[];
  filterKey: string;
  filterLabel: string;
  isDisabled: boolean;
};

function DropdownFilter(props: Props): JSX.Element {
  const {
    filterLabel,
    filterKey,
    filterChoices,
    currentFilterValues,
  } = props;

  return (
    <DropdownButton
      buttonClassName='btn-link'
      dataControllerName='responses-filter'
      label={filterLabel}
      ulClassName='dropdown-checkboxes'
    >
      {
        dropdownChoices(
          filterKey,
          filterChoices,
          currentFilterValues,
        )
      }
      <li className='dropdown-checkboxes-apply'>
        <button
          className='btn btn-primary'
          data-action='responses-filter#applyCheckboxFilter'
          type='submit'
        >Apply
        </button>
      </li>
    </DropdownButton>
  );
}

// private

function dropdownChoices(
  filterKey: string,
  filterChoices: string[],
  currentFilterValues: string[],
): JSX.Element[] {
  currentFilterValues ||= [];

  return filterChoices.map((choice) => {
    const currentlyFiltered = currentFilterValues.includes(choice);

    return (
      <li key={choice}>
        <a
          className='checkbox'
          href='#!'
          onClick={(event): void => {
            $(event.target).closest('.btn-group').addClass('open');
          }}
        >
          <label>
            <input
              data-responses-filter-target='checkbox'
              defaultChecked={currentlyFiltered}
              name={filterKey}
              type='checkbox'
              value={choice}
            />
            {displayChoice(filterKey, choice)}
          </label>
        </a>
      </li>
    );
  });
}

function displayChoice(filterKey: string, choice: string): string {
  if (filterKey === 'status' && choice === 'Routing') {
    return 'Pending Submittal';
  }
  return choice;
}

export default DropdownFilter;
export type { Props };
