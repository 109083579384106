import FieldModel from 'src/doc_editor/fields/models/field';

const Prefill = FieldModel.extend({
  defaults: {
    ...FieldModel.prototype.defaults,
    allowedInNumberFormula: true,
    allowEdits: false,
    content: 'Group Data',
    required: false,
  },

  validate() {
    if (this.get('content') === 'Group Data') {
      return ['You must choose from the list of columns.'];
    }

    return null;
  },
});

export default Prefill;
