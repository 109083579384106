// @ts-strict-ignore
import { ActionEvent, Controller } from '@hotwired/stimulus';

import { findEl } from 'src/helpers/finders';
import StepModel from 'src/step_adder/models/step';
class StepAdderController extends Controller {
  static targets = ['stepRow', 'stepRowTemplate'];

  stepRowTargets: HTMLTableRowElement[];
  stepRowTemplateTarget: HTMLTemplateElement;

  get stepsTable(): HTMLElement {
    return findEl(this.element, 'tbody', '.step-records');
  }

  connect(): void {
    this.addStep();
  }

  addStep(): void {
    const newStep = new StepModel();

    const newStepRow = document.createElement('tr');
    newStepRow.setAttribute('data-step-adder-target', 'stepRow');
    newStepRow.dataset.controller = 'step-row';
    newStepRow.dataset.stepRowStepValue = JSON.stringify(newStep);
    newStepRow.innerHTML = this.stepRowTemplateTarget.innerHTML;

    this.stepsTable.appendChild(newStepRow);

    const stepSeq = this.stepRowTargets.length + 1;
    this.updateSeqContent(newStepRow, stepSeq);
  }

  removeStep(event: ActionEvent & DOMEvent): void {
    event.currentTarget.closest('tr').remove();

    this.stepRowTargets.forEach((step, index) => {
      const newStepSeq = index + 2;
      this.updateSeqContent(step, newStepSeq);
    });
  }

  updateSeqContent(stepRow: HTMLTableRowElement, stepSeq: number): void {
    const stepSeqDisplay = findEl(stepRow, 'div', '.step-number');
    stepSeqDisplay.textContent = `Step ${stepSeq}`;

    const removeStepButton = findEl(stepRow, 'button', '.remove-step');
    removeStepButton.ariaLabel = `Remove step ${stepSeq}`;
  }
}

export default StepAdderController;
