const helpCenterBaseUrl = 'https://help.informedk12.com/hc/en-us/articles/';

export const helpCenterUrls = {
  approval: `${helpCenterBaseUrl}115005265287`,
  bulkApproval: `${helpCenterBaseUrl}360024053873`,
  contactSupport: 'https://help.informedk12.com/hc/en-us/requests/new',
  downloadMultiplePdfs: `${helpCenterBaseUrl}115004679467`,
  editPermission: `${helpCenterBaseUrl}115004751427`,
  editPermissionFields:
    `${helpCenterBaseUrl}7398606382739#h_01G6SK3DBZAAAGX8FEY7DTAX1E`,
  editStepSettings: `${helpCenterBaseUrl}115011305708`,
  notifications: `${helpCenterBaseUrl}4403680599059`,
  routeEditor: `${helpCenterBaseUrl}115011155647`,
  routingQuestion: `${helpCenterBaseUrl}5271365594131`,
  updateApprovalRoute: `${helpCenterBaseUrl}115011155647`,
};

export const fontRatio = 1164.0 / 900.0;
export const docValidExtensions = ['.png', '.pdf', '.jpg', '.jpeg'];
export const defaultPageSize = 50;
export const pageReloadInterval = 86400000; // 24 hours

export const supportEmail = 'support@informedk12.com';

export const CONTENT_NUMBER_REGEX = /\(-?\$?-?(\d*\.?\d+)\)$/;
export const DATE_REGEX = /^\d{4}-\d{2}-\d{2}$/;
export const EMAIL_REGEX = new RegExp(window.gon.emailRegexContent, 'i');
export const FORMULA_FIELD_REGEX = /[f]\d+/ig;
export const NUMBER_REGEX = /^-?\$?(?=.)(\d*|\d{1,3}(,\d{3})*)(\.\d+)?$/;
export const PHONE_REGEX = /^\d{3}-\d{3}-\d{4}$/;
export const SSN_REGEX = /^\d{3}-\d{2}-\d{4}$/;
export const TIME_REGEX = /^([1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/;
export const FORMULA_OPERATORS = ['+', '-', '*', '/'];
