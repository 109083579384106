import FieldModel from 'src/doc_editor/fields/models/field';

const Signature = FieldModel.extend({
  defaults: {
    ...FieldModel.prototype.defaults,
    content: 'Request E-Signature',
    required: false,
  },
});

export default Signature;
