// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';
import { FrameElement } from '@hotwired/turbo';
import { Turbo } from '@hotwired/turbo-rails';

class FilterController extends Controller {
  applyFilter(event: DOMEvent<HTMLInputElement>): void {
    const url = new URL(window.location.href);

    url.searchParams.set(event.currentTarget.name, event.currentTarget.value);
    Turbo.visit(url.toString());
  }

  applyFrameFilter(event: DOMEvent<HTMLInputElement>): void {
    const frame: FrameElement = event.currentTarget.closest('turbo-frame');

    if (frame !== null) {
      const url = new URL(frame.src);

      url.searchParams.set(event.currentTarget.name, event.currentTarget.value);

      frame.src = url.toString();
      frame.reload();
    }
  }
}

export default FilterController;
