// @ts-strict-ignore
import store from 'src/common/store';
import { Json, snakeCaseKeys } from 'src/helpers/json';
import { generateQueryParams } from 'src/responses/helpers/query_params';
import BulkCheckboxController from './bulk_checkbox_controller';
import { pluralizeWithCount } from 'src/helpers/pluralize';
import { generateFilteredFormSetData, generateSelectedFormSetData }
  from 'src/form_sets/helpers/form_set_data_generator';
import { Props } from './responses_index_controller';
import { findEls } from 'src/helpers/finders';

class ResponsesSelectionController extends BulkCheckboxController {
  static values = {
    props: Object,
    ...super.values,
  };

  static targets = [
    'appliedFilter',
    'bulkActionToolbar',
    'deselectAcrossPages',
    'filter',
    'searchInput',
    'searchToolbar',
    'selectAcrossPages',
    ...super.targets,
  ];

  appliedFilterTargets: HTMLDivElement[];
  bulkActionToolbarTarget: HTMLElement;
  deselectAcrossPagesTarget: HTMLElement;
  filterTargets: HTMLDivElement[];
  searchInputTarget: HTMLInputElement;
  searchToolbarTarget: HTMLElement;
  selectAcrossPagesTarget: HTMLElement;

  allSelectedAcrossPages: boolean;
  propsValue: Props;

  connect(): void {
    this.allSelectedAcrossPages = false;
    updateUI(this);
  }

  toggleOne(): void {
    super.toggleOne();
    updateUI(this);
  }

  toggleAll(): void {
    super.toggleAll();
    updateUI(this);
  }

  selectAllAcrossPages(): void {
    this.allSelectedAcrossPages = true;
    super.selectAll();
    updateUI(this);
  }

  deselectAllAcrossPages(): void {
    this.allSelectedAcrossPages = false;
    super.deselectAll();
    updateUI(this);
  }

  setSelectedCountText(): void {
    const selectedCount = getSelectedCount(this);
    this.selectedCountTarget.textContent =
      `${pluralizeWithCount(selectedCount, this.itemDescriptionValue)} selected`;
  }

  setHiddenField(event: DOMEvent): void {
    const hiddenInputs: HTMLInputElement[] =
      findEls(event.target, 'input', '[type="hidden"]');

    hiddenInputs.forEach((element) => {
      if (element.name === 'form_set_data') {
        element.value = JSON.stringify(snakeCaseKeys(generateFormSetData(this)));
      } else if (element.name === 'selected_responses_count') {
        element.value = getSelectedCount(this).toString();
      }
    });
  }

  toggleFiltersEnabled(): void {
    const selectedCount = getSelectedCount(this);
    const hasSearchTerm = Boolean(this.searchInputTarget.value);
    const disable = hasSearchTerm || selectedCount > 0;
    const allFilterTargets = this.filterTargets
      .concat(this.appliedFilterTargets);

    allFilterTargets.forEach((element) => {
      toggleElementEnabled(element, disable);
    });
  }
}

// private

function updateUI(controller: ResponsesSelectionController): void {
  setToolbarVisibility(controller);
  setAcrossPagesOptionVisibility(controller);

  controller.toggleFiltersEnabled();
}

function setToolbarVisibility(controller: ResponsesSelectionController): void {
  setAcrossPagesOptionVisibility(controller);

  const selectedCount = getSelectedCount(controller);

  if (selectedCount > 0) {
    controller.bulkActionToolbarTarget.classList.remove('hide');
    controller.searchToolbarTarget.classList.add('hide');
  } else {
    controller.bulkActionToolbarTarget.classList.add('hide');
    controller.searchToolbarTarget.classList.remove('hide');
  }
}

function setAcrossPagesOptionVisibility(
  controller: ResponsesSelectionController,
): void {
  const allSelected = controller.areAllCheckboxesSelected();
  const { totalPages } = getPagination();

  if (!allSelected || totalPages < 2) {
    controller.allSelectedAcrossPages = false;
    hideSelectAcrossPagesOptions(controller);
  } else if (controller.allSelectedAcrossPages) {
    showDeselectAcrossPagesOption(controller);
  } else {
    showSelectAcrossPagesOption(controller);
  }
}

function hideSelectAcrossPagesOptions(
  controller: ResponsesSelectionController,
): void {
  controller.selectAcrossPagesTarget.classList.add('hide');
  controller.deselectAcrossPagesTarget.classList.add('hide');
}

function showSelectAcrossPagesOption(
  controller: ResponsesSelectionController,
): void {
  controller.selectAcrossPagesTarget.classList.remove('hide');
  controller.deselectAcrossPagesTarget.classList.add('hide');
}

function showDeselectAcrossPagesOption(
  controller: ResponsesSelectionController,
): void {
  controller.selectAcrossPagesTarget.classList.add('hide');
  controller.deselectAcrossPagesTarget.classList.remove('hide');
}

function toggleElementEnabled(element: HTMLElement, disable: boolean): void {
  if (disable) {
    element.setAttribute('aria-disabled', '');
    element.setAttribute('disabled', '');
  } else {
    element.removeAttribute('aria-disabled');
    element.removeAttribute('disabled');
  }
}

function generateFormSetData(
  controller: ResponsesSelectionController,
): Json {
  const { campaign, formGroup, searchParams } = controller.propsValue;
  const queryParams = generateQueryParams(campaign, formGroup, searchParams);

  if (controller.allSelectedAcrossPages) {
    return generateFilteredFormSetData(campaign.id, queryParams);
  }

  const formIds = controller.checkboxTargets
    .filter((checkbox) => { return checkbox.checked; })
    .map((checkbox) => { return checkbox.dataset.recordId; });
  return generateSelectedFormSetData(campaign.id, formIds, queryParams);
}

function getSelectedCount(controller: ResponsesSelectionController): number {
  if (controller.allSelectedAcrossPages) {
    return getPagination().totalCount;
  }

  return controller.checkboxTargets
    .reduce((sum, checkbox) => { return sum + (checkbox.checked ? 1 : 0); }, 0);
}

function getPagination(): Pagination {
  return store.getState().responses.meta.pagination;
}

export default ResponsesSelectionController;
