// @ts-strict-ignore
import React, { ReactNode } from 'react';
import { DayPicker } from 'react-day-picker';

import Icon from 'src/common/components/icon';
import { browserFormatDate } from 'src/helpers/date';
import { closeDialog } from 'src/helpers/dialog';

type Props = {
  filterKey: number | string;
  filterLabel: string;
};

type State = {
  from: Date | null;
  to: Date | null;
};

class DateRangeFilterModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleResetClick = this.handleResetClick.bind(this);
    this.remove = this.remove.bind(this);
    this.state = { from: null, to: null };
  }

  render(): ReactNode {
    const { from, to } = this.state;

    return (
      <div className='wb-modal'>
        <div className='wb-modal--header' id='dialog--title'>
          {this.modalTitle(from, to)}
          <button
            aria-label='Close'
            className='wb-modal--close-button'
            type='button'
            onClick={this.remove}
          >
            <Icon name='close' size='1-5x' />
          </button>
        </div>
        <div className='wb-modal--body'>
          {this.modalBody(from, to)}
        </div>
        <div className='wb-modal--footer'>
          {this.modalFooter(from, to)}
        </div>
      </div>
    );
  }

  remove(): void {
    closeDialog();
  }

  modalTitle(from: Date | null, to: Date | null): JSX.Element {
    return (
      <h1 className='wb-heading-4'>
        {this.renderHeaderText(from, to)}
      </h1>
    );
  }

  modalBody(from: Date | null, to: Date | null): JSX.Element {
    const range = { from, to };

    return (
      <DayPicker
        mode='range'
        numberOfMonths={2}
        selected={range}
        onSelect={this.handleDayClick}
      />
    );
  }

  modalFooter(from: Date | null, to: Date | null): JSX.Element {
    return (
      <div className='modal-btns' data-controller='responses-filter'>
        <button className='btn btn-link' type='button' onClick={this.remove}>
          Cancel
        </button>
        <button
          className='btn btn-primary'
          data-action='responses-filter#applyFilter'
          data-responses-filter-key-param={this.props.filterKey}
          data-responses-filter-value-param={JSON.stringify(this.filterValue())}
          disabled={!from || !to}
          type='button'
          onClick={this.remove}
        >
          Add filter
        </button>
      </div>
    );
  }

  handleDayClick(range): void {
    this.setState(range);
  }

  handleResetClick(event: React.MouseEvent): void {
    event.preventDefault();
    this.setState({ from: null, to: null });
  }

  filterValue(): object {
    const { from, to } = this.state;

    if (!from || !to) { return null; }
    return {
      gte: browserFormatDate(from),
      lte: browserFormatDate(to),
    };
  }

  renderHeaderText(from: Date | null, to: Date | null): JSX.Element {
    const { filterLabel } = this.props;

    if (!from || !to) {
      return <span>{`Select a range for '${filterLabel}'`}</span>;
    }
    return (
      <span>
        {filterLabel}: &nbsp;
        <strong>
          {headerFormatDate(from)} - {headerFormatDate(to)}
        </strong>&nbsp;
        <small><a href='#!' onClick={this.handleResetClick}>Reset</a></small>
      </span>
    );
  }
}

function headerFormatDate(date: Date): string {
  const monthName = date.toLocaleString('en-us', { month: 'short' });
  const day = date.getDate();

  return `${monthName} ${day}`;
}

export default DateRangeFilterModal;
