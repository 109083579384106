// @ts-strict-ignore
// 1. update props to go under `propsValue`
// 2. update `search_params` to be passed in from server
// 3. update `DEFAULT_FILTERS` to be passed in from server
// 4. update `queryParamsGroup` to be passed in from server
// 5. move DEFAULT_QUERY_PARAMS to server
import { ActionEvent, Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';
import debounce from 'lodash/debounce';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import store from 'src/common/store';
import { pageReloadInterval } from 'src/constants';
import Location from 'src/helpers/location';
import { updateResponsesQueryParams as updateQueryParamsRedux }
  from 'src/query_params/action_creators';
import { fetchResponses, updateMeta } from 'src/responses/action_creators';
import ResponsesIndexContainer from 'src/responses/containers/index';
import { generateQueryParams } from 'src/responses/helpers/query_params';

type Props = {
  campaign: Campaign;
  steps: Step[];
  formGroup: FormGroup;
  searchParams: ResponsesQueryParams;
  headers: Responses.TableHeader[];
};

class ResponsesIndexController extends Controller {
  static values = {
    props: Object,
  };

  propsValue: Props;

  connect(): void {
    Location.autoReload(pageReloadInterval);

    const { campaign, steps, formGroup, headers, searchParams } = this.propsValue;

    const queryParams = generateQueryParams(campaign, formGroup, searchParams);
    const pagination = { totalCount: 0, totalPages: 0 };

    store.dispatch(updateQueryParamsRedux(queryParams));
    store.dispatch(updateMeta({ pagination }));
    store.dispatch(fetchResponses(campaign));

    this.handleSearch = debounce(this.handleSearch, 500).bind(this);

    ReactDOM.render(wrapper(campaign, headers, steps), this.element);
  }

  applyPage(event: ActionEvent): void {
    const { pageNum } = event.params;
    const { campaign, formGroup, searchParams } = this.propsValue;
    const { page: currentPageNum } =
      generateQueryParams(campaign, formGroup, searchParams);

    if (pageNum === currentPageNum) { return; }

    const url = new URL(window.location.href);
    url.searchParams.set('page', pageNum);
    Turbo.visit(url.toString());
  }

  handleSearch(event: DOMEvent<null, HTMLInputElement>): void {
    const searchTerm = event.target.value.trim();

    const { campaign, formGroup, searchParams } = this.propsValue;
    const queryParams = generateQueryParams(campaign, formGroup, searchParams);
    const { searchTerm: currentSearchTerm } = queryParams;

    if ((currentSearchTerm || '').trim() === searchTerm) { return; }

    if (searchTerm) {
      applySearchTerm(searchTerm);
    } else {
      removeSearchTerm();
    }
  }

  clearSearch(): void {
    removeSearchTerm();
  }

  handleSort(event: ActionEvent): void {
    const { sortBy } = event.params;

    if (!sortBy) { return; }

    const { campaign, formGroup, searchParams } = this.propsValue;
    const { direction: currentDirection, sortBy: currentSortBy } =
      generateQueryParams(campaign, formGroup, searchParams);

    let direction: SortDirection;

    if (currentSortBy === sortBy && currentDirection === 'asc') {
      direction = 'desc';
    } else {
      direction = 'asc';
    }

    applySort(sortBy, direction);
  }
}

// private

function applySort(sortBy: string, direction: string): void {
  const url = new URL(window.location.href);

  url.searchParams.set('direction', direction);
  url.searchParams.set('sort_by', sortBy);
  url.searchParams.set('page', '1');
  Turbo.visit(url.toString());
}

function applySearchTerm(searchTerm: string): void {
  const url = new URL(window.location.href);

  url.searchParams.set('search_term', searchTerm);
  url.searchParams.set('page', '1');
  Turbo.visit(url.toString());
}

function removeSearchTerm(): void {
  const url = new URL(window.location.href);

  url.searchParams.delete('search_term');
  url.searchParams.set('page', '1');
  Turbo.visit(url.toString());
}

function wrapper(
  campaign: Campaign,
  headers: Responses.TableHeader[],
  steps: Step[],
): JSX.Element {
  return (
    <Provider store={store}>
      <React.StrictMode>
        <ResponsesIndexContainer
          campaign={campaign}
          headers={headers}
          steps={steps}
        />
      </React.StrictMode>
    </Provider>
  );
}

export type { Props };
export default ResponsesIndexController;
