// @ts-strict-ignore
import React from 'react';

import Icon, { IconName } from 'src/common/components/icon';

const WINDOW_SIZE = 2;
const MAX_PAGE_NUMBER_ITEMS = (WINDOW_SIZE * 2) + 1;

type Props = {
  pagination: Pagination;
  compactDisplay?: boolean;
  paginatorClassNames?: string;
  ariaLabel: string;
};
function Paginator(props: Props): JSX.Element {
  const { pagination, compactDisplay, paginatorClassNames, ariaLabel } = props;
  const { totalPages, page, pageSize, totalCount } = pagination;

  if (totalPages === 0) { return null; }

  const showFirstPageTruncate =
    totalPages > MAX_PAGE_NUMBER_ITEMS &&
    page - WINDOW_SIZE > 1;

  return (
    <nav aria-label={ariaLabel}>
      <ul
        className={`paginator ${paginatorClassNames || ''}`}
      >
        {pagesLabel(page, pageSize, totalCount)}
        {prevPageItem(page)}
        {
          !compactDisplay &&
          showFirstPageTruncate &&
          firstPageItem()
        }
        {
          !compactDisplay &&
          pageNumberItems(page, totalPages)
        }
        {nextPageItem(page, totalPages)}
      </ul>
    </nav>
  );
}

Paginator.defaultProps = {
  compactDisplay: false,
};

// private

function pagesLabel(
  page: number,
  pageSize: number,
  totalCount: number,
): JSX.Element {
  const rangeStart = ((page - 1) * pageSize) + 1;
  const rangeEnd = Math.min(rangeStart + pageSize - 1, totalCount);
  const label = `${rangeStart}-${rangeEnd} of ${totalCount}`;

  return <li className='paginator__info'>{label}</li>;
}

function pageNumberItems(
  page: number,
  totalPages: number,
): JSX.Element[] {
  const firstNumber =
    Math.max(
      Math.min(page - WINDOW_SIZE, totalPages - MAX_PAGE_NUMBER_ITEMS + 1),
      1,
    );
  const lastNumber =
    Math.min(
      Math.max(page + WINDOW_SIZE, MAX_PAGE_NUMBER_ITEMS),
      totalPages,
    );

  const pageItems: JSX.Element[] = [];

  for (let pageNumber = firstNumber; pageNumber <= lastNumber; pageNumber++) {
    const isActive = page === pageNumber;

    pageItems.push(pageNumberItem(isActive, pageNumber));
  }
  return pageItems;
}

function pageNumberItem(
  isActive: boolean,
  pageNumber: number,
): JSX.Element {
  return (
    <li key={`page_number-${pageNumber}`}>
      <button
        aria-current={isActive ? 'true' : 'false'}
        className={
          `paginator__button ${isActive ? 'paginator__button--active' : ''}`
        }
        data-action='responses-index#applyPage feedbacks#applyPage'
        data-feedbacks-page-num-param={pageNumber}
        data-responses-index-page-num-param={pageNumber}
        type='button'
      >
        {pageNumber}
      </button>
    </li>
  );
}

function firstPageItem(): JSX.Element[] {
  return [pageNumberItem(false, 1), truncateItem()];
}

function truncateItem(): JSX.Element {
  return (
    <li key='truncate'>
      <div className='paginator__ellipsis'>
        <Icon name='ellipsisHorizontal' />
      </div>
    </li>
  );
}

function pageArrowItem(
  key: string,
  isDisabled: boolean,
  iconName: IconName,
  pageNumber: number,
): JSX.Element {
  return (
    <li key={key}>
      <button
        aria-label={key}
        className='paginator__button'
        data-action='responses-index#applyPage feedbacks#applyPage'
        data-feedbacks-page-num-param={pageNumber}
        data-responses-index-page-num-param={pageNumber}
        disabled={isDisabled}
        type='button'
      >
        <Icon name={iconName} />
      </button>
    </li>
  );
}

function prevPageItem(page: number): JSX.Element {
  const key = 'previous';
  const isDisabled = page === 1;
  const iconName = 'caretLeft';
  const pageNumber = page - 1;

  return pageArrowItem(key, isDisabled, iconName, pageNumber);
}

function nextPageItem(page: number, totalPages: number): JSX.Element {
  const key = 'next';
  const isDisabled = page === totalPages;
  const iconName = 'caretRight';
  const pageNumber = page + 1;

  return pageArrowItem(key, isDisabled, iconName, pageNumber);
}

export default Paginator;
export type { Props };
