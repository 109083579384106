type BeforeUnloadCallback = (event: BeforeUnloadEvent) => string;
const beforeUnloadListeners: { [key: string]: BeforeUnloadCallback } = {};

const Location = {
  addBeforeUnload(key: string): void {
    if (Object.keys(beforeUnloadListeners).includes(key)) { return; }

    const message = 'Do you want to leave this site? ' +
      'Changes you made may not be saved.';

    function callback(event: BeforeUnloadEvent): string {
      event.returnValue = message;
      return message;
    }

    beforeUnloadListeners[key] = callback;
    window.addEventListener('beforeunload', callback);
  },

  autoReload(interval: number): void {
    setTimeout(this.reload.bind(this), interval);
  },

  back(): void {
    window.history.back();
  },

  confirmPageLeave(event: BeforeUnloadEvent): string {
    const message = 'You have unsaved changes. Are you sure?';

    // Note: returnValue is ignored in most browsers. It just needs to be set
    // to a non-null value.
    event.returnValue = message;

    return message;
  },

  downloadFileAndRedirect(downloadPath: string, redirectPath: string): void {
    window.open(downloadPath, '_blank');
    this.navigateTo(redirectPath);
  },

  downloadFileAt(path: string): void {
    this.navigateTo(path);
  },

  isLocalFile(): boolean {
    return window.location.href.startsWith('file:');
  },

  navigateTo(path: string): void {
    if (this.unloading) { return; }

    window.location.href = path;
  },

  reload(): void {
    if (this.unloading) { return; }

    window.location.reload();
  },

  removeBeforeUnload(key: string): void {
    const callback = beforeUnloadListeners[key];

    window.removeEventListener('beforeunload', callback);
    delete beforeUnloadListeners[key];
  },

  replaceHistoryState(state: string): void {
    history.replaceState({}, '', state);
  },

  scrollTo(xCoord: number, yCoord: number): void {
    window.scrollTo(xCoord, yCoord);
  },

  unloading: false,
};

window.addEventListener('unload', () => {
  Location.unloading = true;
});

export default Location;
