import FieldModel from 'src/doc_editor/fields/models/field';

const Input = FieldModel.extend({
  defaults: {
    ...FieldModel.prototype.defaults,
    allowedInNumberFormula: true,
    height: 14,
    minHeight: 14,
    minWidth: 21,
    required: false,
    width: 28,
  },
});

export default Input;
