import React from 'react';

import Icon from 'src/common/components/icon';
import { RemoveFlash } from 'src/flashes/action_creators';
import iconClasses from 'src/helpers/icon';

const FLASH_ICON_CLASS = {
  danger: iconClasses('timesCircle'),
  info: `${iconClasses('refresh')} fa-spin`,
  success: iconClasses('checkCircle'),
  warning: iconClasses('warning'),
};

type StatusType = 'danger' | 'info' | 'success' | 'warning';

type Props = {
  id: number;
  message: string;
  removeFlash: RemoveFlash;
  type: StatusType;
};

function Flash(props: Props): JSX.Element {
  const { type, message, removeFlash, id } = props;

  return (
    <div key={message} className={`alert alert-${type}`}>
      <div className='row'>
        {renderFlashContent(type, message)}
        {renderCloseButton(removeFlash, id)}
      </div>
    </div>
  );
}

function renderFlashContent(type: StatusType, message: string): JSX.Element {
  return (
    <div className='col-xs-10'>
      <div className='media'>
        <div className='media-left media-middle'>
          <i className={`${FLASH_ICON_CLASS[type]} fa-2x`}></i>
        </div>
        <div className='media-body media-middle'>{message}</div>
      </div>
    </div>
  );
}

function renderCloseButton(removeFlash: RemoveFlash, id: number): JSX.Element {
  return (
    <div className='col-xs-2'>
      <div className='text-right'>
        <button
          aria-label='Close Message'
          className='link-text link-no-underline'
          type='button'
          onClick={(event): void => {
            event.stopPropagation();
            removeFlash(id);
          }}
        >
          <Icon name='close' size='2x' />
        </button>
      </div>
    </div>
  );
}

export default Flash;
