import FieldModel from 'src/doc_editor/fields/models/field';

const Date = FieldModel.extend({
  defaults: {
    ...FieldModel.prototype.defaults,
    content: 'mm/dd/yyyy',
    format: 'mm/dd/yyyy',
    mode: 'auto_current',
    required: false,
  },
});

export default Date;
