// @ts-strict-ignore
import React, { ReactNode } from 'react';

import Icon from 'src/common/components/icon';
import PageMessage from 'src/common/components/page_message';
import { helpCenterUrls } from 'src/constants';
import { serializeParams } from 'src/helpers/json';
import { UpdateMeta } from 'src/steps/action_creators';
import StepQuestion from 'src/steps/components/step_card/step_question';
import iconClasses from 'src/helpers/icon';

const STEP_AVG_HEIGHT = 90; // a best guest at the avg height of each node
const STEP_DIAGRAM_OFFSET = 70; // offset for placement of the card

type Props = {
  campaign: Campaign;
  recipients: Recipient[];
  step: Step;
  updateMeta: UpdateMeta;
};

class StepCard extends React.Component<Props, never> {
  render(): ReactNode {
    const { label, seq } = this.props.step;

    return (
      <div key={seq} className='step-card' style={positionToClickedStep(seq)}>
        { this.cardActions() }
        <div className='step-card__section'>
          <h3 className='wb-heading-3 wb-u-margin-b-0'>
            <span className='wb-heading__subtext wb-heading-6'>Step {seq}</span>
            {label}
          </h3>
        </div>
        <hr className='wb-u-margin-v-0' />
        <div className='step-card__section'>
          {this.tabContent()}
        </div>
      </div>
    );
  }

  cardActions(): JSX.Element {
    const { step, updateMeta, campaign: { userPermissions } } = this.props;

    return (
      <React.Fragment>
        <button
          aria-label='Close'
          className='close'
          type='button'
          onClick={(): void => { updateMeta({ selectedStepId: null }); }}
        >
          <Icon name='close' />
        </button>
        {
          userPermissions.includes('edit_step_approver') && editButton(step.seq)
        }
      </React.Fragment>
    );
  }

  tabContent(): JSX.Element {
    const { recipients, step } = this.props;
    const { question, stepsToNotify, ccEmails } = step;
    const notificationsCount = ccEmails.length + stepsToNotify.length;
    const showNotifications = notificationsCount > 0;
    const showQuestion = Boolean(question);

    return (
      <div className='tab-content'>
        <ul className='nav nav-tabs nav-tabs--small wb-u-margin-b-4'>
          <li className='active'>
            <a data-toggle='tab' href='#approvers'>
              Approvers
              {
                recipients.length > 0 &&
                <span className='nav-tabs__badge'>
                  {recipients.length}
                </span>
              }
            </a>
          </li>
          {
            showNotifications &&
            <li>
              <a data-toggle='tab' href='#notifications'>
                CC / Notifications
                <span className='nav-tabs__badge'>
                  {notificationsCount}
                </span>
              </a>
            </li>
          }
          {
            showQuestion &&
            <li>
              <a data-toggle='tab' href='#question'>Routing Question</a>
            </li>
          }
        </ul>
        <div className='tab-pane active' id='approvers'>
          {this.stepContent()}
        </div>
        {
          showNotifications &&
          <div className='tab-pane' id='notifications'>
            {this.notificationsContent()}
          </div>
        }
        {
          showQuestion &&
          <div className='tab-pane' id='question'>
            {this.routingQuestionContent()}
          </div>
        }
      </div>
    );
  }

  stepContent(): JSX.Element {
    const { type } = this.props.step;

    switch (type) {
      case 'LinkStep':
      case 'TrackedLinkStep':
        return this.initiatorStepContent();
      case 'PromptStep':
        return this.promptStepContent();
      case 'RecipientStep':
        return this.recipientStepContent();
      case 'PriorApproverStep':
        return this.priorApproverStepContent();
      case 'SequentialGroupStep':
      case 'FirstComeGroupStep':
        return this.groupStepContent();
      default:
        throw new Error(`unknown type: ${type}`);
    }
  }

  groupStepContent(): JSX.Element {
    const { campaign, step } = this.props;
    const { userPermissions } = campaign;

    const groupPath = `/campaigns/${campaign.id}/group`;
    const emails = step.emailColumns.join(', ');

    return (
      <div className='step-content step-content--scroll'>
        <div className='wb-l-split'>
          <div>
            Forms are sent to <span className='wb-u-font-bold'>{emails} </span>
            people in {campaign.groupName}.
            {step.emailColumns.length > 1 && multipleRecipientsApprovalInfo()}
          </div>
          {
            userPermissions.includes('edit_group') &&
            <a className='wb-u-padding-r-4' href={groupPath}>View Group</a>
          }
        </div>
      </div>
    );
  }

  recipientStepContent(): JSX.Element {
    const { recipients } = this.props;

    return (
      <div className='step-content--scroll'>
        <table className='wb-table wb-table--bordered wb-table--padded'>
          <tbody>
            {recipients.map((recipient) => { return recipientRow(recipient); })}
          </tbody>
        </table>
      </div>
    );
  }

  notificationsContent(): JSX.Element {
    const { step: { stepsToNotify, ccEmails } } = this.props;

    return (
      <React.Fragment>
        <div className='step-content step-content--scroll'>
          {ccEmails.map((email, index) => { return ccEmailRow(index, email); })}
          {stepsToNotify.map((step) => { return stepToNotifyRow(step); })}
        </div>
        <p className='wb-text wb-text--muted wb-u-margin-t-4'>
          <i
            className={
              `${iconClasses('infoCircle')} fa-1-2x text-brand wb-u-margin-r-2`
            }
          />
          These people will receive a view-only link when submissions on this
          step are approved.
        </p>
      </React.Fragment>
    );
  }

  routingQuestionContent(): JSX.Element {
    const { step: { question } } = this.props;

    return (
      <StepQuestion
        manageQuestionButtons={null}
        question={question}
      />
    );
  }

  initiatorStepContent(): JSX.Element {
    const { campaign, step } = this.props;
    const filters = {
      statusGroup: 'active',
      waitingOn: [`Step 1: ${step.label}`],
    };
    const stringifiedFilters = serializeParams({ filters });
    const url = `/campaigns/${campaign.id}/responses?${stringifiedFilters}`;
    const copy = (
      <span>
        Anyone can be an Initiator.
        See what your Initiators are working on in the&nbsp;
        <a href={url}>Responses page</a>.
      </span>
    );

    return noRecipientMessage(copy);
  }

  promptStepContent(): JSX.Element {
    const copy = (
      <span>
        Approvers are entered by people in the previous step.
        <br />
        <a
          href={helpCenterUrls.approval}
          rel='noopener noreferrer'
          target='_blank'
        >
          Learn more
        </a>
      </span>
    );

    return noRecipientMessage(copy);
  }

  priorApproverStepContent(): JSX.Element {
    const { sourceStep } = this.props.step;
    const subtitle = (
      <span>
        Form will be sent to the same approver entered in
        <strong> Step {sourceStep.seq}: {sourceStep.label} </strong>
        for approval
        <br />
        <a
          href={helpCenterUrls.approval}
          rel='noopener noreferrer'
          target='_blank'
        >
          Learn more
        </a>
      </span>
    );

    return (
      <PageMessage
        icon={`${iconClasses('userPlus')} fa-5x`}
        subtitle={subtitle}
        title={`Send to Step ${sourceStep.seq}: ${sourceStep.label}`}
      />
    );
  }
}

// private

function positionToClickedStep(seq: number): { top: string } {
  return { top: `${((seq - 1) * STEP_AVG_HEIGHT) + STEP_DIAGRAM_OFFSET}px` };
}

function noRecipientMessage(copy: JSX.Element): JSX.Element {
  return (
    <PageMessage
      icon='fa fa-users fa-5x'
      subtitle={copy}
      title='No approvers specified'
    />
  );
}

function recipientRow(recipient): JSX.Element {
  const { id, name, email, recipientSubstitution } = recipient;

  return (
    <tr key={id}>
      <td className='wb-u-break-word' colSpan={recipientSubstitution ? 1 : 2}>
        <strong>{name}</strong>
        <br />
        {email}
      </td>
      {
        recipientSubstitution &&
        <td>
          <div className='wb-l-media wb-u-break-word'>
            <Icon iconClassName='wb-u-margin-r-1 wb-u-margin-t-1' name='share' />
            <span className='sr-only'>Forwarding to</span>
            {recipientSubstitution.email}
          </div>
          <div className='wb-text wb-text--muted wb-text--small'>
            {recipientSubstitution.startAt} - {recipientSubstitution.endAt}
          </div>
        </td>
      }
    </tr>
  );
}

function ccEmailRow(index: number, email: string): JSX.Element {
  return (
    <div key={`cc-${index}`} className='step-content__row'>
      {email}
    </div>
  );
}

function stepToNotifyRow(step: number): JSX.Element {
  const role = step === 1 ? 'initiator' : 'approver';

  return (
    <div key={`notify-${step}`} className='step-content__row'>
      Step {step} {role}
    </div>
  );
}

function editButton(seq: number): JSX.Element {
  return (
    <a
      className='step-card__edit wb-button wb-button--primary wb-button--small'
      href={`route/edit#step-${seq}`}
    >
      Edit
    </a>
  );
}

function multipleRecipientsApprovalInfo(): JSX.Element {
  return (
    <div className='wb-u-margin-t-2'>
      <i
        className={
          `${iconClasses('infoCircle')} fa-1-2x text-brand wb-u-margin-r-2`
        }
      />
      Only one approval is needed.
    </div>
  );
}

export default StepCard;
