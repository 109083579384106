import FieldModel from 'src/doc_editor/fields/models/field';

const Formula = FieldModel.extend({
  defaults: {
    ...FieldModel.prototype.defaults,
    allowedInNumberFormula: true,
    format: 'number',
    mode: 'number',
    precision: 2,
  },
});

export default Formula;
