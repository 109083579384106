import Checkbox from 'src/doc_editor/fields/checkbox';
import Date from 'src/doc_editor/fields/date';
import Dropdown from 'src/doc_editor/fields/dropdown';
import Formula from 'src/doc_editor/fields/formula';
import Input from 'src/doc_editor/fields/input';
import Prefill from 'src/doc_editor/fields/prefill';
import ReferenceNumber from 'src/doc_editor/fields/reference_number';
import Signature from 'src/doc_editor/fields/signature';
import StaticText from 'src/doc_editor/fields/static_text';
import Time from 'src/doc_editor/fields/time';

const TypeToFieldMap = {
  Checkbox,
  Date,
  Dropdown,
  Formula,
  Input,
  Prefill,
  ReferenceNumber,
  Signature,
  StaticText,
  Time,
};

export default TypeToFieldMap;
