// @ts-strict-ignore
import { connect } from 'react-redux';

import { applySearchTerm } from 'src/responses/action_creators';
import ResponsesSearchPaginate from 'src/responses/components/search_paginate';

function mapStateToProps(state: State) {
  return { searchTerm: state.responses.meta.queryParams.searchTerm };
}

export default connect(
  mapStateToProps,
  { applySearchTerm },
)(ResponsesSearchPaginate);
