// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';

import { findEl } from 'src/helpers/finders';
import { pluralizeWithCount } from 'src/helpers/pluralize';

class BulkCheckboxController extends Controller {
  static targets = ['checkbox', 'checkboxAll', 'submitButton', 'selectedCount'];
  static values = { itemDescription: String, showEmptyCount: Boolean };

  hasSubmitButtonTarget: boolean;
  hasSelectedCountTarget: boolean;
  hasCheckboxAllTarget: boolean;
  checkboxAllTarget: HTMLInputElement;
  checkboxTargets: HTMLInputElement[];
  submitButtonTarget: HTMLButtonElement;
  selectedCountTarget: HTMLElement;
  itemDescriptionValue: string;
  showEmptyCountValue: boolean;

  connect(): void {
    this.setButtonState();
    this.setSelectedCountText();
  }

  selectAll(): void {
    setAllCheckboxes(true, this);
    this.setButtonState();
    this.setSelectedCountText();
  }

  deselectAll(): void {
    setAllCheckboxes(false, this);
    this.setButtonState();
    this.setSelectedCountText();
  }

  toggleAll(): void {
    setAllCheckboxes(this.checkboxAllTarget.checked, this);
    this.setButtonState();
    this.setSelectedCountText();
  }

  toggleOne(): void {
    setCheckboxAllState(this);
    this.setButtonState();
    this.setSelectedCountText();
  }

  setButtonState(): void {
    if (this.hasSubmitButtonTarget) {
      const allUnchecked = this.checkboxTargets.every((checkbox) => {
        return !checkbox.checked;
      });

      this.submitButtonTarget.disabled = allUnchecked;
    }
  }

  setSelectedCountText(): void {
    if (this.hasSelectedCountTarget) {
      const allChecked = this.checkboxTargets.reduce(
        (sum, checkbox) => { return sum + (checkbox.checked ? 1 : 0); },
        0,
      );

      if (allChecked > 0 || this.showEmptyCountValue) {
        this.selectedCountTarget.textContent =
          `${pluralizeWithCount(allChecked, this.itemDescriptionValue)} selected`;
        this.selectedCountTarget.classList.remove('hide-for-now');
        this.selectedCountTarget.classList.add('wb-pill', 'wb-pill--brand-alt');
      } else {
        this.selectedCountTarget.textContent = '';
        this.selectedCountTarget.classList.add('hide-for-now');
        this.selectedCountTarget.classList.remove('wb-pill', 'wb-pill--brand-alt');
      }
    }
  }

  setHiddenField(event: DOMEvent): void {
    const recordIds = this.checkboxTargets
      .filter((checkbox) => { return checkbox.checked; })
      .map((checkbox) => { return checkbox.dataset.recordId; });
    const hiddenField = findEl(event.target, 'input', '.record-ids');

    hiddenField.value = recordIds.join(',');
  }

  areAllCheckboxesSelected(): boolean {
    return this.checkboxTargets.every((checkbox) => {
      return checkbox.checked || checkbox.disabled;
    });
  }
}

// private

function setAllCheckboxes(
  value: boolean,
  controller: BulkCheckboxController,
): void {
  controller.checkboxTargets.forEach((checkbox) => {
    if (!checkbox.disabled) {
      checkbox.checked = value;
      checkbox.dispatchEvent(new Event('change'));
    }
  });

  setCheckboxAllState(controller);
}

function setCheckboxAllState(controller: BulkCheckboxController): void {
  if (!controller.hasCheckboxAllTarget) { return; }

  controller.checkboxAllTarget.checked = controller.areAllCheckboxesSelected();
}

export default BulkCheckboxController;
