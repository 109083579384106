import React, { ReactNode } from 'react';

import DropdownButton from 'src/common/components/dropdown_button';

type Props = {
  currentFilterValues: string[] | undefined;
  filterKey: number | string;
  filterLabel: string;
};

class TextFilter extends React.Component<Props, never> {
  render(): ReactNode {
    const { filterLabel } = this.props;

    return (
      <DropdownButton
        buttonClassName='btn-link'
        label={filterLabel}
        ulClassName='dropdown-form'
      >
        <li>
          {this.renderForm()}
        </li>
      </DropdownButton>
    );
  }

  renderForm(): JSX.Element {
    const { filterLabel, currentFilterValues, filterKey } = this.props;

    return (
      <form
        key={filterLabel}
        className='form-inline'
        data-controller='responses-filter'
      >
        <div className='input-group'>
          <input
            className='form-control'
            data-responses-filter-target='input'
            placeholder={`Enter ${filterLabel}`}
            type='text'
          />
          <span className='input-group-btn'>
            <button
              className='btn btn-primary'
              data-action='responses-filter#applyTextFilter:prevent'
              data-responses-filter-current-value-param={currentFilterValues}
              data-responses-filter-key-param={filterKey.toString()}
              type='submit'
            >Apply
            </button>
          </span>
        </div>
      </form>
    );
  }
}

export default TextFilter;
export type { Props };
