import classNames from 'classnames';
import React from 'react';

type Props = {
  required: boolean;
  selected: boolean;
};

function Time(props: Props): JSX.Element {
  const { required, selected } = props;

  return (
    <div
      className={
        classNames('requested-field', 'field-container', { required, selected })
      }
    >
      <div className='text-input adjustable-font'>
        00:00 AM
      </div>
    </div>
  );
}

export default Time;
