// @ts-strict-ignore
import 'src/extensions/jquery-ui/draggable';

import Field from 'src/doc_editor/field';
import { fieldHeight, fieldWidth } from 'src/doc_editor/fields/helpers/sizing';
import $template from 'src/helpers/dollar_template';

class ReferenceNumber extends Field {
  constructor(opts) {
    super(opts);

    if (!opts.initCallbacks) { return; }

    this.model.on(
      'change:referencePrefix change:startingReferenceNumber',
      this.updateFieldSize.bind(this),
    );
  }

  shouldShowContextMenuOnInit(): boolean { return true; }

  $preview(): JQuery {
    const $previewElement = $template('reference-number-field-preview-template');

    $previewElement.css({
      height: this.$element.height(),
      left: this.$element.position().left,
      top: this.$element.position().top,
      width: this.$element.width(),
    });

    return $previewElement;
  }

  updateFieldSize(): void {
    const prefix = this.model.get('referencePrefix');
    const startingReferenceNumber = this.model.get('startingReferenceNumber');
    const paddedNumber = startingReferenceNumber.toString().padStart(7, '0');
    const referenceNumberPlaceholder = `${prefix}${paddedNumber}`;

    const fontSize = this.model.get('fontSize');
    const newWidth = fieldWidth(fontSize, referenceNumberPlaceholder);
    const newHeight = fieldHeight(fontSize);

    this.model.set({ height: newHeight, width: newWidth });
  }
}

export default ReferenceNumber;
