// @ts-strict-ignore
import bindAll from 'lodash/bindAll';
import React, { ReactNode } from 'react';

import Icon from 'src/common/components/icon';
import { assert } from 'src/helpers/assertion';

type Props = {
  name: string;
  update: (attributes) => void;
  value: string;
};

type State = {
  editMode: boolean;
  value: string;
};

class EditableLabel extends React.Component<Props, State> {
  private inputRef = React.createRef<HTMLInputElement>();

  constructor(props: Props) {
    super(props);
    bindAll(this, 'makeEditable', 'updateState', 'lockAndUpdate', 'blurOnEnter');

    this.state = { editMode: false, value: this.props.value };
  }

  render(): ReactNode {
    return (
      <div className='editable-label'>
        {!this.state.editMode && this.renderStaticDisplay()}
        {this.state.editMode && this.renderDynamicDisplay()}
      </div>
    );
  }

  renderStaticDisplay(): JSX.Element {
    return (
      <button
        aria-label='Edit label'
        className='static-display wb-u-button-reset'
        type='button'
        onClick={this.makeEditable}
      >
        <span className='static-label'>{this.state.value || '[edit]'}</span>
        <Icon name='pencil' />
      </button>
    );
  }

  renderDynamicDisplay(): JSX.Element {
    return (
      <input
        ref={this.inputRef}
        aria-label='Edit label'
        className='label-input'
        name={this.props.name}
        value={this.state.value}
        onBlur={this.lockAndUpdate}
        onChange={this.updateState}
        onKeyDown={this.blurOnEnter}
      />
    );
  }

  updateState(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ value: event.target.value });
  }

  lockAndUpdate(): void {
    const { name, update } = this.props;
    const data = {};

    data[name] = this.state.value;
    update(data);

    this.makeUneditable();
  }

  makeEditable(): void {
    this.setState({ editMode: true }, () => {
      const inputEl = assert(this.inputRef.current);

      inputEl.focus();
      inputEl.select();
    });
  }

  makeUneditable(): void {
    this.setState({ editMode: false });
  }

  blurOnEnter(event: React.KeyboardEvent<HTMLInputElement>): void {
    if (event.key !== 'Enter') { return; }

    this.lockAndUpdate();
  }
}

export default EditableLabel;
export type { Props };
