// @ts-strict-ignore
import CsvValidator from 'src/components/csv_validator';
import DataMatcher from 'src/group_wizard/data_matcher';
import ReviewerColumn from 'src/group_wizard/reviewer_column';
import { findEl, findEls } from 'src/helpers/finders';
import flash from 'src/helpers/flash';
import { events, publish } from 'src/helpers/pub_sub';
import template from 'src/helpers/template';
import timesMap from 'src/helpers/times_map';

class DataReviewer {
  $element: JQuery;
  element: HTMLElement;
  mappings: { [key: string]: string };
  csvValidator: CsvValidator;
  dataMatcher: DataMatcher;

  constructor(element: HTMLElement) {
    this.$element = $(element);
    this.element = element;
    this.mappings = {};

    this.csvValidator = new CsvValidator();

    this.$element.find('.reviewer-simple.wizard-next').on('click', () => {
      this.dataMatcher.saveCurrentHeader();

      const currentExpectedHeaders = this.dataMatcher.getCurrentHeader();

      publish(events.SIMPLE_REVIEWER_STEP_SELECTED, currentExpectedHeaders);
    });
  }

  get thead(): HTMLTableSectionElement {
    return findEl(this.element, 'thead', '');
  }

  get tbody(): HTMLTableSectionElement {
    return findEl(this.element, 'tbody', '');
  }

  get reviewerColumnsContainer(): HTMLElement {
    return findEl(this.element, 'div', '.reviewer-columns');
  }

  get reviewerColumns(): HTMLElement[] {
    return findEls(this.reviewerColumnsContainer, 'div', '.reviewer-column');
  }

  get $expectedFields(): JQuery {
    return this.$element.find('.reviewer-expected-fields li');
  }

  get $chosenFields(): JQuery {
    return this.$element.find('.reviewer-chosen-fields li');
  }

  get $headerSelects(): JQuery {
    return this.$element.find('.group_column');
  }

  get $submitBtn(): JQuery {
    return this.$element.find('.wizard-next');
  }

  get $statusMsg(): JQuery {
    return this.$element.find('.alert-danger');
  }

  get $multiEmailRadios(): JQuery {
    return this.$element.find('.multi-email-radio');
  }

  get $multiEmailElements(): JQuery {
    return this.$element.find('.reviewer-multi-email');
  }

  get $simpleReviewerElements(): JQuery {
    return this.$element.find('.reviewer-simple');
  }

  hide(): void {
    this.$element.hide();
  }

  show(): void {
    this.$element.show();
  }

  // used to determine if user tried using back button during matching
  isValidState(expectedHeaders, index) {
    const columns = this.dataMatcher.getColumns();
    let increment = 0;

    // true when all expected headers up to index are satisfied
    return expectedHeaders.reduce(
      (memo, header) => {
        if (increment < index) {
          increment += 1;
          // true if there is a single matching column that is locked
          return memo && columns.reduce(
            (innerMemo, column) => {
              return innerMemo ||
                (column.getMatchedHeader() === header && column.isLocked());
            },
            false,
          );
        }
        return memo;
      },
      true,
    );
  }

  handleFileSelect(files, successCallback): void {
    const reader = new window.FileReader();

    reader.onload = (event: ProgressEvent<FileReader>): void => {
      this.readerOnload(event, successCallback);
    };
    reader.readAsText(files[0], 'UTF-8');
  }

  readerOnload(event: ProgressEvent<FileReader>, successCallback): void {
    flash.reset();
    this.csvValidator.loadData(event.target.result);
    const csvColumns = this.csvValidator.getColumns();
    const spreadsheetHeaders = this.csvValidator.getHeaders();

    const maxRowsToShow = Math.min(5, this.csvValidator.numRows);
    const csvRows = timesMap(maxRowsToShow, (rowIndex) => {
      return spreadsheetHeaders.map((header) => {
        return csvColumns[header][rowIndex];
      });
    });

    const errorMessage = this.validateUpload(csvRows);

    if (errorMessage) {
      flash('error', errorMessage);
      return;
    }

    this.createSpreadsheetTable(spreadsheetHeaders, csvRows);
    this.createSpreadsheetColumns(spreadsheetHeaders, csvRows);
    this.createSelectMenus(spreadsheetHeaders);
    successCallback();
  }

  validateUpload(csvRows) {
    this.csvValidator.validateUniqueHeaders();
    const errorMessage = this.csvValidator.getErrors();

    if (!csvRows.length) {
      return 'The file you chose is blank. Please choose another.';
    } else if (errorMessage.length > 0) {
      return errorMessage;
    }
    return false;
  }

  // create the preview of the CSV
  createSpreadsheetTable(spreadsheetHeaders, rows): void {
    this.thead.innerHTML = '';
    this.tbody.innerHTML = '';

    const headerRow = this.thead.insertRow();

    spreadsheetHeaders.forEach((header) => {
      const cell = document.createElement('th');

      cell.textContent = header;
      headerRow.appendChild(cell);
    });

    rows.forEach((row) => {
      const rowElement = this.tbody.insertRow();

      row.forEach((item) => {
        const cell = rowElement.insertCell();

        cell.textContent = item;
      });
    });

    const ellipsisRow = this.tbody.insertRow();

    rows[0].forEach(() => {
      const cell = ellipsisRow.insertCell();

      cell.textContent = '...';
    });
  }

  // Create the preview of the CSV
  createSpreadsheetColumns(spreadsheetHeaders, rows): void {
    let columnHTML = '';

    spreadsheetHeaders.forEach((header, index) => {
      // Get the indexed entry for each row
      const entries: string[] = [];

      rows.forEach((row) => {
        entries.push(row[index]);
      });
      entries.push('...');

      columnHTML += template('reviewer-column-template', { entries, header });
    });

    this.reviewerColumnsContainer.innerHTML = columnHTML;

    const columns = this.reviewerColumns.map((column) => {
      return new ReviewerColumn(column);
    });

    this.dataMatcher = new DataMatcher(columns);
  }

  createSelectMenus(spreadsheetHeaders): void {
    const $headerSelect = $(
      '<select aria-label="CSV Column" class="group_column">',
    );

    $headerSelect.append('<option>--</option>');
    spreadsheetHeaders.forEach((header) => {
      $headerSelect.append($(`<option value="${header.toLowerCase()}">` +
      `${header}</option>`));
    });

    this.$expectedFields.each((index, item) => {
      const fieldName = $(item).data('key').toLowerCase();
      const $chosenField = this.$chosenFields.eq(index);

      $chosenField.empty().append($headerSelect.clone());

      // auto select options where possible
      const $matchedOption = $chosenField.find(`option[value="${fieldName}"]`);

      if ($matchedOption.length > 0) {
        $matchedOption.attr('selected', 'selected');
      } else if (fieldName === 'full name') {
        // Let's also match 'name' to 'full name'
        $chosenField.find('option[value="name"]').attr('selected', 'selected');
      }
    });

    this.disableMultiEmailSelected();

    // recalculate submit info on any changes
    this.$chosenFields.find('select').on('change', () => {
      this.validateMultiEmail();
      this.disableMultiEmailSelected();
    });
  }

  uploadSpreadsheet(): void {
    this.$element.find('.reviewer-mappings').val(JSON.stringify(this.mappings));
    this.$element.find('.reviewer-csv-raw').val(this.csvValidator.rawCsv());
  }

  disableMultiEmailSelected(): void {
    const $options = this.$headerSelects.find('option');

    // enable everything
    $options.each((_index, option) => {
      const $option = $(option);

      $option.attr('disabled', null);
    });

    // disable selected again
    this.$headerSelects.find(':selected').each((_index, selectedOption) => {
      const $selectedOption = $(selectedOption);
      const fieldName = $selectedOption.text().toLowerCase();

      this.$headerSelects.find(`option[value="${fieldName}"]`)
        .attr('disabled', 'disabled');
      $selectedOption.attr('disabled', null);
    });
  }

  // see if the fields we are expecting have been selected
  validateMultiEmail(): boolean {
    this.mappings = {};

    // load mappings of current headers to what we're looking for
    this.$chosenFields.each((index, selectedField) => {
      const $selectedField = $(selectedField);

      const picked = $selectedField.find('option:selected').text();

      if (normalizeHeader(picked) !== '--') {
        this.mappings[this.$expectedFields.eq(index).data('key')] = picked;
      }
    });

    this.csvValidator.setMappings(this.mappings);
    this.csvValidator.validateNames();
    this.csvValidator.validateMultiEmail();
    this.csvValidator.validateBlanks();
    this.csvValidator.validateEmails();

    return this.finishValidation();
  }

  validateSimple(): boolean {
    this.mappings = this.dataMatcher.getMatchMappings();
    this.csvValidator.setMappings(this.mappings);
    this.csvValidator.validateBlanks();
    this.csvValidator.validateEmails();

    return this.finishValidation();
  }

  finishValidation(): boolean {
    const error = this.csvValidator.getErrors();

    if (error) {
      this.$submitBtn.prop('disabled', true);
      this.statusMsg(error);

      return false;
    }
    this.$submitBtn.prop('disabled', false);
    this.statusMsg('');

    return true;
  }

  statusMsg(message): void {
    if (message) {
      this.$statusMsg.html(message).show();
    } else {
      this.$statusMsg.hide();
    }
  }

  setMultiEmail(isMultiEmail: boolean): void {
    if (isMultiEmail) {
      this.validateMultiEmail();
      this.$simpleReviewerElements.hide();
      this.$multiEmailElements.show();
      this.$multiEmailRadios.filter('[value=true]').prop('checked', true);
      this.disableMultiEmailSelected();
    } else {
      this.validateSimple();
      this.$multiEmailElements.hide();
      this.$simpleReviewerElements.show();
      this.$multiEmailRadios.filter('[value=false]').prop('checked', true);
    }
  }

  setExpectedHeader(headers, index): void {
    const header = headers[index];

    for (let increment = index + 1; increment < headers.length; increment++) {
      this.dataMatcher.clearHeader(headers[increment]);
    }

    this.$element.find('.reviewer-expected-header').text(header);
    this.dataMatcher.useMatcher(header);

    if (!this.dataMatcher.hasSelected() || !this.validateSimple()) {
      // Disable continue until we choose a header
      this.$submitBtn.prop('disabled', true);
    }

    this.dataMatcher.matchCallback = (): void => {
      if (this.validateSimple()) {
        this.$submitBtn.prop('disabled', false);
      }
    };
  }

  getColumns() {
    return this.dataMatcher.getColumns();
  }

  clearMatcher(): void {
    if (this.dataMatcher) {
      this.dataMatcher.clear();
    }
  }
}

// private

function normalizeHeader(word: string): string {
  let normalized = word.toLowerCase();

  normalized = normalized.trim();
  return normalized.replace(/ /g, '_');
}

export default DataReviewer;
