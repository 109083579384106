// @ts-strict-ignore
import { normalize } from 'normalizr';
import { InferThunkActionCreatorType } from 'react-redux';

import feedbackSchema from 'src/feedbacks/schema';
import { fetchGet, fetchPut } from 'src/helpers/fetch';
import notify from 'src/helpers/notify';

function setFeedbacks(feedbacks) {
  return { payload: feedbacks, type: 'feedbacks/SET' };
}

function updateMeta(meta) {
  return { payload: meta, type: 'feedbacks/UPDATE_META' };
}

function fetchFeedbacks(campaignId: number) {
  return function fetchFeedbacksAsync(dispatch, getState) {
    const { meta: previousMeta } = getState().feedbacks;
    const feedbacksPath = `/api/campaigns/${campaignId}/feedbacks`;

    return fetchGet(feedbacksPath, { ...previousMeta.queryParams })
      .then(({ data, meta }) => {
        const { entities, result } = normalize(data, [feedbackSchema]);

        dispatch(setFeedbacks({ byId: entities.feedbacks, orderedIds: result }));
        dispatch(updateMeta(meta));
      }, notify);
  };
}

function updateFeedbackServer(feedback, { formRequestId, token }) {
  const url = `/api/form_requests/${formRequestId}/feedbacks?token=${token}`;
  const data = { feedback };

  return () => {
    return fetchPut(url, data);
  };
}

export {
  setFeedbacks,
  updateMeta,
  fetchFeedbacks,
  updateFeedbackServer,
};

export type UpdateMeta = InferThunkActionCreatorType<typeof updateMeta>;
export type UpdateFeedbackServer =
  InferThunkActionCreatorType<typeof updateFeedbackServer>;
