// @ts-strict-ignore
import Backbone from 'backbone';
import React from 'react';
import ReactDOM from 'react-dom';

import ContextMenuWrapper from 'src/doc_editor/context_menu_wrapper';
import Checkbox from 'src/doc_editor/fields/components/common/checkbox';
import PrefillContextMenu from
  'src/doc_editor/fields/components/context_menus/prefill';
import { findEl } from 'src/helpers/finders';

type UpdateParams = {
  number: number;
  allowEdits: boolean;
  required?: boolean;
};

class PrefillContextMenuWrapper extends ContextMenuWrapper {
  model: Backbone.Model;

  constructor(opts) {
    super(opts);

    this.renderEditableCheckbox = this.renderEditableCheckbox.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.setAllowEdits = this.setAllowEdits.bind(this);
    this.model = new Backbone.Model();

    const $selector = $('<span class="selector-container"></span>');
    const $editable = $('<span class="editable-container col-md-12"></span>');

    this.$element.find('.context-menu-body').append([$selector, $editable]);
    this.renderEditableCheckbox(opts);

    this.model.on('change', this.renderBody);

    $selector.on('change', () => {
      if ($selector.find('select')[0].selectedIndex === 0) { return; }

      const selectedVal = $selector.find('option:selected').val();

      if (typeof selectedVal === 'string') {
        this.opts.setContent(selectedVal);
      }
    });

    this.initDisplay();

    this.setGroupColumns(opts.columns);
  }

  get editableContainer(): HTMLElement {
    return findEl(this.element, 'span', '.editable-container');
  }

  get selectorContainer(): HTMLElement {
    return findEl(this.element, 'span', '.selector-container');
  }

  renderEditableCheckbox(opts): void {
    const attrs = {
      attribute: 'allowEdits',
      isChecked: opts.allowEdits,
      label: 'Allow edits?',
      onChange: this.setAllowEdits,
    };

    ReactDOM.render(
      <Checkbox {...attrs} />,
      this.editableContainer,
    );
  }

  renderBody(): void {
    ReactDOM.render(
      <React.StrictMode>
        <PrefillContextMenu
          columns={this.model.get('columns')}
          number={this.opts.number}
        />
      </React.StrictMode>,
      this.selectorContainer,
    );
  }

  initDisplay(): void {
    ReactDOM.render(
      <div>Loading...</div>,
      this.selectorContainer,
    );
  }

  setGroupColumns(columns): void {
    this.model.set('columns', columns);
  }

  setAllowEdits(allowEdits: boolean): void {
    const { number } = this.opts;
    const updateParams: UpdateParams = { allowEdits, number };

    if (!allowEdits) { updateParams.required = false; }

    this.updateFields(updateParams);
  }
}

export default PrefillContextMenuWrapper;
