// @ts-strict-ignore
import Backbone from 'backbone';

import autoLabel from 'src/doc_editor/fields/helpers/auto_label';
import NumberGenerator from 'src/doc_editor/fields/helpers/number_generator';

const Field = Backbone.Model.extend({
  defaults: {
    allowedInNumberFormula: false,
    allowEdits: true,
    content: '',
    dropdownId: null,
    editable: true,
    editableBySpecialApprover: false,
    formatLocked: false,
    selected: false,
  },

  idAttribute: 'number',

  initialize(opts) {
    this.set('number', NumberGenerator.next(opts.number));

    if (!this.get('label')) {
      this.set('label', autoLabel(this as Backbone.Model));
    }
  },
});

export default Field;
