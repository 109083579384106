import React from 'react';

import Icon from 'src/common/components/icon';

type Props = {
  filterKey: string;
  filterLabel: string;
  filterValue: string;
};
function PillButton(props: Props): JSX.Element {
  const {
    filterKey,
    filterLabel,
    filterValue,
  } = props;
  const label = `${filterLabel}: ${filterValue}`;

  return (
    <div
      className='btn btn-pill'
      data-responses-selection-target='appliedFilter'
    >
      {label}
      <button
        aria-label={`Remove Filter: ${label}`}
        className='link-text wb-u-margin-l-2'
        data-action='responses-filter#clearFilter'
        data-responses-filter-current-value-param={filterValue}
        data-responses-filter-key-param={filterKey}
        data-responses-selection-target='appliedFilter'
        type='button'
      >
        <Icon name='close' />
      </button>
    </div>
  );
}

export default PillButton;
export type { Props };
