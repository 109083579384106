// @ts-strict-ignore
import React, { ReactNode } from 'react';

import { BoundUpdateFields } from 'src/chux/editable_fields/store';
import TIME_INTERVALS from 'src/fields/config/time_intervals';

type Props = {
  interval: string;
  number: number;
  updateFields: BoundUpdateFields;
};

class TimeContextMenu extends React.Component<Props, never> {
  constructor(props: Props) {
    super(props);
    this.updateInterval = this.updateInterval.bind(this);
  }

  render(): ReactNode {
    const { interval } = this.props;

    const defaultSelected = TIME_INTERVALS.none.interval;
    const selected = isValidOption(interval) ? interval : defaultSelected;

    return (
      <div>
        <label>
          Time Intervals:
          <select
            name='interval'
            value={selected}
            onChange={this.updateInterval}
          >
            {intervalOptions()}
          </select>
        </label>
      </div>
    );
  }

  updateInterval(event: React.ChangeEvent<HTMLSelectElement>): void {
    const { updateFields, number } = this.props;
    const interval = parseInt(event.target.value, 10);

    updateFields({ interval, number });
  }
}

// private

function isValidOption(interval: string): boolean {
  return Object.values(TIME_INTERVALS).some((option) => {
    return option.interval === interval;
  });
}

function intervalOptions(): JSX.Element[] {
  return Object.values(TIME_INTERVALS).map((option) => {
    const { label, interval } = option;

    return <option key={interval} value={interval}>{label}</option>;
  });
}

export type { Props };
export default TimeContextMenu;
