// @ts-strict-ignore
import Field from 'src/doc_editor/field';
import FormulaDependencyUpdater from 'src/doc_editor/formula_dependency_updater';
import $template from 'src/helpers/dollar_template';

class Formula extends Field {
  number: number;

  constructor(opts) {
    super(opts);

    if (!opts.initCallbacks) { return; }

    this.number = opts.model.get('number');
    this.updateFields = opts.updateFields;

    const payload = FormulaDependencyUpdater.updateDependencies(
      this.model.get('number'),
      this.model.get('content'),
      opts.getFields(),
    );

    this.updateFields(payload);
  }

  $preview(): JQuery {
    const $previewElement = $template('formula-field-preview-template');

    $previewElement.css({
      left: this.$element.position().left,
      top: this.$element.position().top,
    });

    $previewElement.find('.formula-value').text('Calculated value.');

    return $previewElement;
  }

  shouldShowContextMenuOnInit(): boolean { return true; }

  remove(): void {
    const payload = FormulaDependencyUpdater.removeDependencies(this.number);

    this.updateFields(payload);
    super.remove();
  }
}

export default Formula;
