// @ts-strict-ignore
import React from 'react';

import EditableBadge from 'src/doc_editor/fields/components/common/editable_badge';
import PrefillBadge from 'src/doc_editor/fields/components/common/prefill_badge';
import StepNumber from 'src/doc_editor/fields/components/common/step_number';
import FieldLogic from 'src/doc_editor/fields/components/common/field_logic';
import FieldNumber from 'src/doc_editor/fields/components/common/field_number';

type Props = {
  badgeType: BadgeType;
  activeFormulaNumber?: number;
  editableBySpecialApprover?: boolean;
  hasFieldRule?: boolean;
  prefillable?: boolean;
  seq?: number;
  number: number;
};

function Badge(props: Props): JSX.Element {
  const {
    activeFormulaNumber,
    badgeType,
    editableBySpecialApprover,
    hasFieldRule,
    prefillable,
    seq,
    number,
  } = props;

  if (activeFormulaNumber) { return null; }

  switch (badgeType) {
    case 'step_number':
      return <StepNumber seq={seq} />;
    case 'editable':
      return (
        <EditableBadge editableBySpecialApprover={editableBySpecialApprover} />
      );
    case 'prefill':
      return <PrefillBadge prefillable={prefillable} />;
    case 'field_number':
      return <FieldNumber number={number} />;
    case 'field_logic':
      return <FieldLogic hasFieldRule={hasFieldRule} />;
    default:
      return null;
  }
}

export default Badge;
export type { Props };
