// @ts-strict-ignore
import { getActiveNumbers } from 'src/chux/doc_editor/single_store';
import {
  BoundGetFields,
  BoundRemoveFields,
  BoundUpdateFields,
} from 'src/chux/editable_fields/store';
import isAllowedEditableBySpecialApprover from
  'src/doc_editor/helpers/is_allowed_editable_by_special_approver';
import isFieldRequireable from 'src/doc_editor/helpers/is_field_requireable';
import isFieldResizable from 'src/doc_editor/helpers/is_field_resizable';
import { fieldFormats } from 'src/fields/config/formats';
import PRECISION_OPTIONS from 'src/fields/config/number_precision';
import HotKey from 'src/helpers/hot_key';

type DocEditorHotKeyParams = {
  steps: Step[];
  updateFields: BoundUpdateFields;
  removeFields: BoundRemoveFields;
  getFields: BoundGetFields;
  duplicateFields: (fields: Field[]) => Backbone.Model[];
  setActiveFields: (fieldNumbers: Set<number>) => void;
};

class DocEditorHotKey {
  execute(
    hotKey: HotKey,
    { steps, updateFields, removeFields, getFields,
      duplicateFields, setActiveFields }: DocEditorHotKeyParams,
  ): boolean {
    const activeNumbers = getActiveNumbers();
    if (activeNumbers.length === 0) { return false; }

    let hotKeyExecuted = true;
    const activeFields = getActiveFieldsData(activeNumbers, getFields());

    switch (true) {
      case hotKey.isNumber(): {
        const fieldsToUpdate =
          this.selectStepForActiveFields(hotKey, steps, activeFields);

        if (fieldsToUpdate) { updateFields(fieldsToUpdate); }
        break;
      }
      case hotKey.isKey('r'):
        updateFields(this.toggleActiveFieldsRequired(activeFields));
        break;
      case hotKey.isKey('d'):
        updateFields(this.toggleActiveDateFieldsMode(activeFields));
        break;
      case hotKey.isBackspace():
        this.removeActiveFields(activeFields).forEach(removeFields);
        break;
      case hotKey.isKey('p'):
        updateFields(this.setFormatToPhone(activeFields));
        break;
      case hotKey.isKey('e'):
        updateFields(this.toggleEditableByApprover(activeFields));
        break;
      case hotKey.isKey('n'):
        updateFields(
          this.setFormatToNumber(
            activeFields,
            { precision: PRECISION_OPTIONS[0].precision },
          ),
        );
        break;
      case hotKey.isKey('m'):
        updateFields(
          this.setFormatToNumber(
            activeFields,
            { precision: PRECISION_OPTIONS[2].precision },
          ),
        );
        break;
      case hotKey.isKey('c'):
        duplicateActiveFields(activeFields, duplicateFields, setActiveFields);
        break;
      case hotKey.isShiftActive() && hotKey.isArrow():
        if (hotKey.isLeftArrow() || hotKey.isUpArrow()) {
          updateFields(alignFields(hotKey, activeFields));
        }
        break;
      case hotKey.isArrow():
        updateFields(moveFields(hotKey, activeFields));
        break;
      case hotKey.isKey('['):
      case hotKey.isKey(']'):
      case hotKey.isKey('='):
      case hotKey.isKey("'"):
        updateFields(resizeFields(hotKey, activeFields));
        break;
      default:
        hotKeyExecuted = false;
    }

    return hotKeyExecuted;
  }

  selectStepForActiveFields(
    hotKey: HotKey,
    steps: Step[],
    activeFields: Field[],
  ): Field[] | null {
    const step = steps[Number(hotKey.key) - 1];
    if (!step) { return null; }

    return activeFields.map((field) => {
      return { ...field, stepId: step.id };
    });
  }

  toggleActiveFieldsRequired(activeFields: Field[]): Field[] {
    const requireableActiveFields = activeFields.filter((field) => {
      return isFieldRequireable(field);
    });
    const areAnyRequired = requireableActiveFields.some((field) => {
      return field.required;
    });

    return requireableActiveFields.map((field) => {
      return { ...field, required: !areAnyRequired };
    });
  }

  toggleActiveDateFieldsMode(activeFields: Field[]): Field[] {
    const activeDateFields = activeFields.filter((field) => {
      return field.type === 'Date';
    });

    const areAnyAutodate = activeDateFields.some((field) => {
      return field.mode === 'auto_current';
    });

    return activeDateFields.map((field) => {
      return { ...field, mode: areAnyAutodate ? 'user_input' : 'auto_current' };
    });
  }

  removeActiveFields(activeFields: Field[]): { number: number }[] {
    const activeNumbers = removeTriggerFieldWarning(activeFields);
    return activeNumbers.map((number) => { return { number }; });
  }

  setFormatToPhone(activeFields: Field[]): Field[] {
    return activeFields.map((field) => {
      if (field.type === 'Input') {
        return { ...field, format: fieldFormats['xxx-xxx-xxxx'].label };
      }
      return field;
    });
  }

  toggleEditableByApprover(activeFields: Field[]): Field[] {
    const allowedFields = activeFields.filter((field) => {
      return isAllowedEditableBySpecialApprover(field);
    });
    const areAnyEditable = allowedFields.some((field) => {
      return field.editableBySpecialApprover;
    });

    return allowedFields.map((field) => {
      return { ...field, editableBySpecialApprover: !areAnyEditable };
    });
  }

  setFormatToNumber(
    activeFields: Field[],
    { precision }: { precision: number },
  ): Field[] {
    return activeFields.map((field) => {
      if (field.type === 'Input') {
        return { ...field, format: fieldFormats.number.label, precision };
      }
      return field;
    });
  }
}

// private

function getActiveFieldsData(
  activeNumbers: number[],
  fields: FieldsByNumber,
): Field[] {
  return activeNumbers.map((number) => { return fields[number]; });
}

function getActiveFieldsHeight(activeFields: Field[]): number {
  const tops = activeFields.map((field) => { return field.yPos; });
  const bottoms =
    activeFields.map((field) => { return field.yPos + field.height; });

  return Math.max(...bottoms) - Math.min(...tops);
}

function getActiveFieldsLeftmostPosition(activeFields: Field[]): number {
  const lefts = activeFields.map((field) => { return field.xPos; });

  return Math.min(...lefts);
}

function getActiveFieldsTopmostPosition(activeFields: Field[]): number {
  const tops = activeFields.map((field) => { return field.yPos; });

  return Math.min(...tops);
}

function alignFields(hotKey: HotKey, activeFields: Field[]): Field[] {
  let positionChange = {};

  if (hotKey.isLeftArrow()) {
    const left = getActiveFieldsLeftmostPosition(activeFields);

    positionChange = { xPos: left };
  } else if (hotKey.isUpArrow()) {
    const top = getActiveFieldsTopmostPosition(activeFields);

    positionChange = { yPos: top };
  }

  return activeFields.map((field) => { return { ...field, ...positionChange }; });
}

function duplicateActiveFields(
  activeFields: Field[],
  duplicateFields: (fields: Field[]) => Backbone.Model[],
  setActiveFields: (fieldNumbers: Set<number>) => void,
): void {
  const newFieldModels = duplicateFields(activeFields);
  const activeFieldsHeight = getActiveFieldsHeight(activeFields);

  newFieldModels.forEach((fieldModel) => {
    fieldModel.set({ yPos: fieldModel.get('yPos') + activeFieldsHeight });
  });

  const newFieldNumbers = new Set<number>(newFieldModels.map((field) => {
    return field.get('number');
  }));

  setActiveFields(newFieldNumbers);
}

function moveFields(hotKey: HotKey, activeFields: Field[]): Field[] {
  let xIncrement = 0;
  let yIncrement = 0;

  if (hotKey.isLeftArrow()) { xIncrement = -2; }
  if (hotKey.isRightArrow()) { xIncrement = 2; }
  if (hotKey.isUpArrow()) { yIncrement = -2; }
  if (hotKey.isDownArrow()) { yIncrement = 2; }

  return activeFields.map((field) => {
    return {
      ...field,
      xPos: field.xPos + xIncrement,
      yPos: field.yPos + yIncrement,
    };
  });
}

function resizeFields(hotKey: HotKey, activeFields: Field[]): Field[] {
  const keyToSizeChange = {
    "'": { height: 1, width: 0 },
    '=': { height: -1, width: 0 },
    '[': { height: 0, width: -1 },
    ']': { height: 0, width: 1 },
  };
  const sizeChange = keyToSizeChange[hotKey.key];
  const resizableFields = activeFields.filter(isFieldResizable);

  return resizableFields.map((field) => {
    const minWidth = field.minWidth || 0;
    const minHeight = field.minHeight || 0;

    return {
      ...field,
      height: Math.max(minHeight, field.height + sizeChange.height),
      width: Math.max(minWidth, field.width + sizeChange.width),
    };
  });
}

function removeTriggerFieldWarning(activeFields: Field[]): number[] {
  const fields = activeFields.filter((field) => {
    if (field.isTriggerField) {
      const warning = 'CAREFUL! You have selected a field that is used in field ' +
        'logic and removing it would affect what fields people can fill out.' +
        '\n\n Are you sure you want to delete this field?';

      return confirm(warning);
    }
    return true;
  });
  return fields.map((field) => { return field.number; });
}

export type { DocEditorHotKeyParams };
export default new DocEditorHotKey();
