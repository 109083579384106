// @ts-strict-ignore
import uniqueId from 'lodash/uniqueId';

class StepModel {
  cid: string;

  constructor() {
    this.cid = uniqueId();
  }
}

export default StepModel;
