// @ts-strict-ignore
import bindAll from 'lodash/bindAll';
import React, { ReactNode } from 'react';

import fetchApproverLists from 'src/helpers/approver_lists';
import { fetchGet } from 'src/helpers/fetch';
import { pluralizeWithCount } from 'src/helpers/pluralize';
import { hide, show } from 'src/helpers/visibility';

type Props = { [key: string]: never };

type State = {
  recipients: Recipient[];
};

class ApproverListAdder extends React.Component<Props, State> {
  private approverListRecipientsRef = React.createRef<HTMLDivElement>();

  constructor(props: Props) {
    super(props);
    this.state = { recipients: [] };
    bindAll(
      this,
      'renderDropdown',
      'renderDropdownOptions',
      'renderApproverSection',
      'renderApproverTable',
      'renderApproverTableHeader',
      'renderApproverRows',
      'onApproverListChanged',
      'updateRecipientList',
    );
  }

  render(): ReactNode {
    return (
      <React.Fragment>
        <label
          className='wb-label'
          htmlFor='approver-list-adder'
        >
          Approver List
        </label>
        {this.renderDropdown()}
        {this.renderApproverSection()}
      </React.Fragment>
    );
  }

  renderDropdown(): JSX.Element {
    return (
      <div className='wb-dropdown'>
        <select
          className='wb-dropdown__select wb-u-margin-t-1 wb-u-margin-b-4'
          data-step-row-target='approverListSelect'
          id='approver-list-adder'
          name='routing_wizard[steps][][approver_list_id]'
          onChange={(event) => { this.onApproverListChanged(event); }}
        >
          <option value=''>Select approver list</option>
          {this.renderDropdownOptions()}
        </select>
      </div>
    );
  }

  renderDropdownOptions(): JSX.Element[] {
    const approverLists = fetchApproverLists();

    return approverLists.map(({ id, name }) => {
      return <option key={id} value={id}>{name}</option>;
    });
  }

  renderApproverSection(): JSX.Element | boolean {
    const recipientsCount = this.state.recipients.length;
    const numApproversText = pluralizeWithCount(recipientsCount, 'approver');

    return (
      <div
        ref={this.approverListRecipientsRef}
        data-step-row-target='approverListRecipients'
      >
        <p>
          This list contains
          <span className='wb-u-font-bold'> {numApproversText}</span>
        </p>
        <div className='recipients-table'>
          {this.renderApproverTable()}
        </div>
      </div>
    );
  }

  renderApproverTable(): JSX.Element {
    const classes = 'wb-table wb-table--small wb-table--padded wb-table--bordered';

    return (
      <table className={classes}>
        {this.renderApproverTableHeader()}
        <tbody data-step-row-target='approverListContainer'>
          {this.renderApproverRows()}
        </tbody>
      </table>
    );
  }

  renderApproverTableHeader(): JSX.Element {
    return (
      <thead>
        <tr>
          <th>Name/Title</th>
          <th>Email</th>
        </tr>
      </thead>
    );
  }

  renderApproverRows(): JSX.Element[] {
    return this.state.recipients.map((recipient) => {
      return (
        <tr key={recipient.id}>
          <td>{recipient.name}</td>
          <td>{recipient.email}</td>
        </tr>
      );
    });
  }

  async onApproverListChanged(
    event: React.ChangeEvent<HTMLSelectElement>,
  ): Promise<void> {
    const approverListId = event.target.value;
    let recipients: Recipient[] = [];

    if (approverListId) {
      const url = `/api/approver_lists/${approverListId}/recipients`;

      recipients = await fetchGet(url)
        .then(({ data }) => { return data; })
        .catch(() => { return []; });
    }

    this.updateRecipientList(recipients);
  }

  updateRecipientList(recipients: Recipient[]): void {
    this.setState({ recipients }, () => {
      if (recipients.length > 0) {
        show(this.approverListRecipientsRef.current);
      } else {
        hide(this.approverListRecipientsRef.current);
      }
    });
  }
}

export default ApproverListAdder;
