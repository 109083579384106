// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';
import A11yDialog from 'a11y-dialog';

import checkFileHelper from 'src/helpers/check_file';
import Dialog from 'src/helpers/dialog';
import $template from 'src/helpers/dollar_template';
import { findEl, findEls } from 'src/helpers/finders';

const VALID_FILE_MESSAGE = 'There must be at least one file';

abstract class FileUploaderController extends Controller {
  $element: JQuery<Element>;
  $errorMsg: JQuery;
  errorMsg: string;
  validExtensions: string[];
  hasTitleTarget: boolean;
  titleTarget: HTMLInputElement;

  static targets = ['title'];

  connect(): void {
    this.$element = $(this.element);

    this.removeFile = this.removeFile.bind(this);
    this.validateAndSubmit = this.validateAndSubmit.bind(this);

    this.errorMsg = VALID_FILE_MESSAGE;
    this.$errorMsg = $(this.errorMessage);

    // Validate title and file presence
    this.$element.find('.uploader-submit').on('click', this.validateAndSubmit);

    this.updateUIElements();
  }

  get errorMessage(): HTMLElement {
    return findEls(this.element, 'div', '.alert-danger')[0];
  }

  validateAndSubmit(): boolean {
    if (!this.titleIsValid() || !this.areFilesValid()) {
      this.$errorMsg.html(this.errorMsg).show();
      return false;
    }
    this.$errorMsg.hide();
    return true;
  }

  onSubmit?(): void;

  validateFiles?(filenames: string[]): boolean;

  titleIsValid(): boolean {
    return this.hasTitleTarget ? Boolean(this.titleTarget.value) : true;
  }

  checkRequired(numFiles: number): void {
    if (this.$element.hasClass('required')) {
      this.$element.toggleClass('empty', numFiles === 0);
    }
  }

  numFiles(): number {
    const attached = document.querySelectorAll(
      '.js-submitted-attachments  > tbody > tr, .uploader-filename',
    );

    return attached.length;
  }

  updateUIElements(): void {
    const numFiles = this.numFiles();

    this.checkRequired(numFiles);

    const noVisibleAddBtn =
        this.$element.find('.uploader-file-selector:visible').length === 0;

    if (numFiles < this.maxFiles() && noVisibleAddBtn) {
      this.addFileSelect();
    }
  }

  addFileSelect(): void {
    const $fileSelect = $template('uploader-file-select-template');

    this.attachEventListeners($fileSelect);

    this.$element.find('.uploader-file-selects').append($fileSelect);
  }

  attachEventListeners($fileSelect: JQuery): void {
    const $input = $fileSelect.find('.uploader-file-selector input[type="file"]');

    $input.on('change', () => {
      const files: File[] = Array.from($input.prop('files'));

      if (files.length + this.numFiles() > this.maxFiles()) {
        $input.val('');
        Dialog.alert(`Too many files selected; total allowed: ${this.maxFiles()}`);
        return;
      }

      const areFilenamesValid = files.every((file) => {
        return checkFileHelper(this.validExtensions, file.name);
      });

      if (files.length > 0 && areFilenamesValid) {
        this.addFiles($fileSelect, files);
      } else {
        $input.val('');
        const element = findEl(
          document.body,
          'div',
          '[data-a11y-dialog="uploader-unsupported-modal"]',
        );
        const dialog = new A11yDialog(element);

        dialog.show();

        // add new row for next file, remove highlights as necessary
        this.updateUIElements();
      }
    });
  }

  addFiles($fileSelect: JQuery, files: File[]): void {
    $fileSelect.find('.uploader-file-selector').hide();
    const $fileRow = $template('file-row-template', { files });

    $fileRow.find('.uploader-file-remove').on('click', this.removeFile);
    $fileSelect.append($fileRow);

    // add new row for next file, remove highlights as necessary
    this.updateUIElements();
  }

  removeFile(event: DOMEvent): void {
    const $element = $(event.currentTarget);

    $element.closest('.uploader-file-select').remove();
    this.updateUIElements();
  }

  areFilesValid(): boolean {
    const uploader =
      this.$element.find('.uploader-file-selector input[type="file"]');
    const filenames = Array.from(uploader)
      .flatMap((fileInput) => {
        const files: File[] = Array.from($(fileInput).prop('files'));

        return files.map((file) => { return file.name; });
      }).filter((filename) => { return filename; });

    if (filenames.length <= 0 ||
    // If we have a custom validator, call it and make sure it returns true
      (this.validateFiles && !this.validateFiles(filenames))) {
      return false;
    }
    return true;
  }

  abstract maxFiles(): number;
}

export default FileUploaderController;
