// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';
import { Blob, DirectUpload } from 'activestorage';

import Dialog from 'src/helpers/dialog';
import notify from 'src/helpers/notify';
import template from 'src/helpers/template';

type UploadResult = {
  filename: string;
  signedId: string;
};

function getDirectUploadData(file: File): Promise<UploadResult> {
  const url = '/rails/active_storage/direct_uploads';
  const directUpload = new DirectUpload(file, url);

  return new Promise((resolve, reject) => {
    directUpload.create((error: Error, blob: Blob) => {
      const result: UploadResult = {
        filename: file.name,
        signedId: '',
      };

      if (error || blob.byte_size === 0) {
        const context = {
          error,
          filename: file.name,
          signedId: blob && blob.signed_id,
          size: file.size,
          type: file.type,
        };

        notify('Direct upload failed.', context);
        reject(result);
      } else {
        result.signedId = blob.signed_id;
        resolve(result);
      }
    });
  });
}
export default class extends Controller {
  static targets = [
    'blobId',
    'fileContainer',
    'fileField',
    'fileRow',
    'submitButton',
  ];

  blobIdTarget: HTMLInputElement;
  fileContainerTarget: HTMLElement;
  fileFieldTarget: HTMLElement;
  fileRowTarget: HTMLElement;
  submitButtonTarget: HTMLButtonElement;

  async addFile(event: DOMEvent<HTMLInputElement>): Promise<void> {
    const [uploadedFile] = event.currentTarget.files;

    this.submitButtonTarget.disabled = true;
    try {
      const { signedId, filename } = await getDirectUploadData(uploadedFile);

      this.blobIdTarget.value = signedId;
      this.replaceFileField(filename);
    } catch {
      Dialog.alert('Failed to upload file. Please check your file and try again.');
    }
    this.submitButtonTarget.disabled = false;
  }

  replaceFileField(filename: string): void {
    this.fileFieldTarget.remove();
    this.fileContainerTarget.insertAdjacentHTML(
      'beforeend',
      template('attachment-uploader-file-row-template', { filename }),
    );
  }

  removeFile(): void {
    this.blobIdTarget.value = '';
    this.fileRowTarget.remove();
    this.fileContainerTarget.insertAdjacentHTML(
      'beforeend',
      template('attachment-uploader-file-field-template'),
    );
  }
}
