import FieldModel from 'src/doc_editor/fields/models/field';

const Checkbox = FieldModel.extend({
  defaults: {
    ...FieldModel.prototype.defaults,
    content: 'X',
    required: false,
  },
});

export default Checkbox;
