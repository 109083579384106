// @ts-strict-ignore
import { CONTENT_NUMBER_REGEX } from 'src/constants';
import validateUrl from 'src/helpers/validate_url';

const errorCheckers = {
  Checkbox: errorsOnField,
  Date: errorsOnField,
  Dropdown: dropdownErrors,
  Formula: errorsOnField,
  Input: errorsOnField,
  Prefill: errorsOnField,
  ReferenceNumber: referenceNumberErrors,
  Signature: errorsOnField,
  StaticText: staticTextErrors,
  Time: errorsOnField,
};

function validateField(field): string[] {
  const checkForErrors = errorCheckers[field.type];

  if (!checkForErrors) {
    throw new Error(`missing error checker for ${field.type}`);
  }

  return checkForErrors(field);
}

// private

function errorsOnField(field): string[] {
  if (!field.errors) { return []; }
  return field.errors.map((error) => { return `${field.label}: ${error}`; });
}

function dropdownErrors(field): string[] {
  const { content, dropdownId, format } = field;
  const optionsContainNums = allContainFloats(content.split(';'));

  if (!dropdownId && content === '') {
    return [`${field.label}: No choices have been entered.`];
  } else if (format === 'number' && !optionsContainNums) {
    return [
      `${field.label}: All choices need to contain numbers. Ex format: Text (3.4)`,
    ];
  }
  return [];
}

function referenceNumberErrors(field): string[] {
  const { startingReferenceNumber } = field;

  if ((/^\d+$/).test(startingReferenceNumber)) { return []; }

  return [`${field.label}: Starting reference number must be a number`];
}

function staticTextErrors(field): string[] {
  const { content, format, link } = field;

  if (content === '') {
    return [`${field.label}: There is no value entered.`];
  } else if (format === 'number' && !isFinite(content)) {
    return [`${field.label}: This field's value needs to be a number.`];
  } else if (link && !validateUrl(link)) {
    const message = `
      ${field.label}: URL must be valid.
      Did you remember to precede the URL with "https://"?
    `;

    return [message];
  }
  return [];
}

function allContainFloats(choices): boolean {
  return choices.every(isFloat) || choices.every(hasFloat);
}

function isFloat(text): boolean {
  return isFinite(text.replace(/^\$/, ''));
}

function hasFloat(text): boolean {
  return CONTENT_NUMBER_REGEX.test(text);
}

export default validateField;
