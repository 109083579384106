import React, { ReactNode } from 'react';

import ResponsesActions from 'src/responses/components/actions';
import ResponsesTableFrame from 'src/responses/components/table_frame';
import ResponsesAppliedFiltersContainer
  from 'src/responses/containers/applied_filters';
import PageMessage from 'src/common/components/page_message';
import iconClasses from 'src/helpers/icon';

type Props = {
  campaign: Campaign;
  headers: Responses.TableHeader[];
  isFetching: boolean;
  pagination: Pagination;
  queryParams: ResponsesQueryParams;
  responses: FormResponse[];
  steps: Step[];
};

class ResponsesIndex extends React.Component<Props, never> {
  render(): ReactNode {
    return (
      <div className='responses-content'>
        <div className='responses-main'>
          {this.responsesTable()}
        </div>
      </div>
    );
  }

  responsesTable(): JSX.Element {
    const { campaign, headers, pagination, queryParams } = this.props;
    const { formGroup } = queryParams;

    return (
      <div>
        <ResponsesActions
          campaign={campaign}
          formGroup={formGroup}
          headers={headers}
          pagination={pagination}
          userFormGroupDescriptions={campaign.userFormGroupDescriptions}
        />
        <ResponsesAppliedFiltersContainer campaign={campaign} headers={headers} />
        {this.loadingMessage()}
        {this.responsesData()}
      </div>
    );
  }

  responsesData(): JSX.Element | null {
    const {
      campaign,
      headers,
      isFetching,
      pagination,
      queryParams,
      responses,
      steps,
    } = this.props;

    if (isFetching) { return null; }

    return (
      <ResponsesTableFrame
        campaign={campaign}
        headers={headers}
        pagination={pagination}
        queryParams={queryParams}
        responses={responses}
        steps={steps}
      />
    );
  }

  loadingMessage(): JSX.Element | null {
    const { isFetching } = this.props;
    if (!isFetching) { return null; }

    return (
      <div className='responses-loading'>
        <PageMessage
          icon={`${iconClasses('circleNotch')} fa-2x fa-spin`}
          title='Loading...'
        />
      </div>
    );
  }
}

export type { Props };
export default ResponsesIndex;
