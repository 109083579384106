import Location from 'src/helpers/location';

class ResponseRowController {
  $element: JQuery;
  element: HTMLElement;

  constructor(element: HTMLElement) {
    this.$element = $(element);
    this.element = element;

    this.$element.on('click', (event) => {
      // Don't open new window if user is clicking on a link or input field
      const clickableSelector = '.response, a, input';
      const $closestClickable = $(event.target).closest(clickableSelector);

      if ($closestClickable.is('a, input')) { return; }

      const { href } = this.element.dataset;

      if (href) { Location.navigateTo(href); }
    });
  }
}

export default ResponseRowController;
