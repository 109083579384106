import React, { Fragment } from 'react';

import StepCardContainer from 'src/steps/containers/step_card';
import StepDiagramContainer from 'src/steps/containers/step_diagram';

type Props = {
  campaign: Campaign;
  selectedStep: Step | null;
};

function StepsIndex(props: Props): JSX.Element {
  const { campaign, campaign: { userPermissions }, selectedStep } = props;

  function editRouteButton(): JSX.Element {
    return (
      <Fragment>
        <p className='wb-text'>or</p>
        <a
          className='wb-button'
          href={`/campaigns/${campaign.id}/route/edit`}
        >
          Edit approval route
        </a>
      </Fragment>
    );
  }

  function stepCardDisplay(): JSX.Element {
    if (selectedStep) {
      return <StepCardContainer />;
    }
    return (
      <div className='no-step-selected-message text-center'>
        <p className='wb-heading-3'>Click on each step to learn more.</p>
        { userPermissions.includes('edit_approval_route') && editRouteButton() }
      </div>
    );
  }

  return (
    <div className='row'>
      <div className='col-sm-5'>
        <div className='wb-u-margin-l-4'>
          <StepDiagramContainer />
        </div>
      </div>
      <div className='col-sm-7'>
        { userPermissions.includes('view_step_approver') && stepCardDisplay() }
      </div>
    </div>
  );
}

export default StepsIndex;
export type { Props };
