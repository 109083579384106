import { Controller } from '@hotwired/stimulus';

class WizardChooseDistributionController extends Controller {
  static targets = ['choice', 'link'];

  choiceTargets!: HTMLElement[];
  linkTarget!: HTMLInputElement;

  selectChoice(event: ActionEventWithStringParams & DOMEvent): void {
    this.choiceTargets.forEach((choice) => {
      choice.classList.remove('active');
    });

    const selectedChoice = event.currentTarget;

    selectedChoice.classList.add('active');
    this.linkTarget.value = event.params.link;
  }
}

export default WizardChooseDistributionController;
