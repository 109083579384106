import FieldModel from 'src/doc_editor/fields/models/field';
import TIME_INTERVALS from 'src/fields/config/time_intervals';

const Time = FieldModel.extend({
  defaults: {
    ...FieldModel.prototype.defaults,
    content: '00:00 AM',
    format: 'HH:MM AM',
    interval: TIME_INTERVALS.none.interval,
    required: false,
  },
});

export default Time;
