import React from 'react';

import Icon from 'src/common/components/icon';

type Props = {
  remove: () => void;
};

function RemoveIcon(props: Props): JSX.Element {
  return (
    <button
      aria-label='Remove Field'
      className='remove-icon'
      type='button'
      onClick={props.remove}
    >
      <Icon name='timesCircle' size='2x' />
    </button>
  );
}

export default RemoveIcon;
