import React, { ReactNode } from 'react';
import validateEmail from 'src/helpers/validate_email';

type RecipientInput = {
  email?: string;
  name?: string;
};

type Props = {
  approverListContainerRows: HTMLCollectionOf<HTMLTableRowElement>;
  approverListId: string;
  recipientsContainerRows: HTMLCollectionOf<HTMLTableRowElement>;
  stepType: {
    clientType: ClientStepType;
    type: StepType;
  };
  skippable: boolean;
  specialApprover: boolean;
  stepLabel: string;
};

class StepSelectionsRow extends React.Component<Props, never> {
  render(): ReactNode {
    const {
      approverListId,
      skippable,
      specialApprover,
      stepLabel,
      stepType: { clientType, type },
    } = this.props;

    return (
      <React.Fragment>
        <div className='wb-u-margin-b-4'>
          <p className='wb-label'>Step Label</p>
          <div>{stepLabel || '--'}</div>
        </div>
        <div className='wb-u-margin-b-4'>
          <p className='wb-label'>Step Type</p>
          <div>
            {type === 'RecipientStep' ? 'Recipient Step' : 'Prompt Step'}
          </div>
        </div>
        {
          (specialApprover || skippable) &&
          this.renderStepSettings(skippable, specialApprover)
        }
        {
          this.isRecipientStep() &&
          this.renderRecipientsSection(approverListId, clientType)
        }
      </React.Fragment>
    );
  }

  renderStepSettings(skippable: boolean, specialApprover: boolean): JSX.Element {
    return (
      <div className='wb-u-margin-b-4'>
        <p className='wb-label'>Additional options</p>
        <ul className='wb-list wb-list--empty'>
          {
            skippable &&
            <li>
              This step can be skipped by the previous approver
            </li>
          }
          {
            specialApprover &&
            <li>
              Approver can edit fields
            </li>
          }
        </ul>
      </div>
    );
  }

  renderRecipientsSection(
    approverListId: string,
    clientType: ClientStepType,
  ): JSX.Element {
    return (
      <div>
        <p className='wb-label'>Recipients</p>
        {this.renderRecipients(approverListId, clientType)}
      </div>
    );
  }

  renderRecipients(
    approverListId: string,
    clientType: ClientStepType,
  ): JSX.Element {
    if (clientType === 'ApproverListStep' && !approverListId) {
      return (
        <p className='text-danger'>
          No approver list selected
        </p>
      );
    }

    const recipients = this.recipients().map((recipient, index) => {
      if (isValid(recipient)) {
        return (
          <li key={index}>
            {`${recipient.name} <${recipient.email}>`}
          </li>
        );
      }
      return (
        <li key={index} className='text-danger'>
          Invalid recipient input
        </li>
      );
    });

    return (
      <ul className='wb-list wb-list--empty'>
        {recipients}
      </ul>
    );
  }

  isRecipientStep(): boolean {
    return this.props.stepType.type === 'RecipientStep';
  }

  recipients(): RecipientInput[] {
    if (this.props.stepType.clientType === 'ApproverListStep') {
      return this.approverListRecipients();
    }

    return this.inputRecipients();
  }

  inputRecipients(): RecipientInput[] {
    const recipientsRows = this.props.recipientsContainerRows;

    if (!recipientsRows) { return [{}]; }

    return Array.from(recipientsRows).map((row) => {
      const [nameInput, emailInput] = row.querySelectorAll('input');

      return { email: emailInput?.value || '', name: nameInput?.value || '' };
    });
  }

  approverListRecipients(): RecipientInput[] {
    const recipientsRows = this.props.approverListContainerRows;

    if (!recipientsRows) { return []; }

    return Array.from(recipientsRows).map((row) => {
      const [nameTd, emailTd] = row.querySelectorAll('td');

      return { email: emailTd?.textContent || '', name: nameTd?.textContent || '' };
    });
  }
}

function isValid(recipient: RecipientInput): boolean {
  return Boolean(recipient.name) && validateEmail(recipient.email || '');
}

export default StepSelectionsRow;
export type { Props, RecipientInput };
