import React, { ReactNode } from 'react';

import ApproverListAdder from './approver_list_adder';

import fetchApproverLists from 'src/helpers/approver_lists';
import StepModel from 'src/step_adder/models/step';
import RecipientAdder from 'src/step_adder/recipient_adder';

type Props = {
  step: StepModel;
};

class StepAdvancedOptionsRow extends React.Component<Props, never> {
  render(): ReactNode {
    const { step } = this.props;

    const approverListsAvailable = fetchApproverLists().length > 0;

    return (
      <React.Fragment>
        <div className='wb-form-group'>
          <label
            className='wb-label'
            htmlFor={`step-label-${step.cid}`}
          >
            Step Label
          </label>
          <input
            className='wb-input-field wb-u-margin-b-4'
            data-step-row-target='stepLabel'
            id={`step-label-${step.cid}`}
            name='routing_wizard[steps][][label]'
            placeholder='e.g. Principals'
            type='text'
          />
        </div>
        <fieldset>
          <legend className='wb-label'>Step Settings</legend>
          <p className='wb-text wb-text--muted wb-u-margin-b-2'>
            How should the previous approver enter the approver for this step?
          </p>
          <div className='wb-form-group'>
            <label
              className='wb-radio wb-u-font-normal'
              htmlFor={`user-defined-list-option-${step.cid}`}
            >
              <input
                className='wb-radio__input'
                data-action='step-row#changeStepType'
                data-step-row-client-type-param='RecipientStep'
                data-step-row-step-cid-param={step.cid}
                data-step-row-target='recipientStepInput'
                defaultChecked
                id={`user-defined-list-option-${step.cid}`}
                name={`step-${step.cid}`}
                type='radio'
              />
              The approver selects from a list of approvers that I define
            </label>
            {approverListsAvailable ? (
              <label
                className='wb-radio wb-u-font-normal'
                htmlFor={`approver-list-option-${step.cid}`}
              >
                <input
                  className='wb-radio__input'
                  data-action='step-row#changeStepType'
                  data-step-row-client-type-param='ApproverListStep'
                  data-step-row-step-cid-param={step.cid}
                  data-step-row-target='approverListStepInput'
                  id={`approver-list-option-${step.cid}`}
                  name={`step-${step.cid}`}
                  type='radio'
                />
                The approver selects from a centralized approver list
              </label>
            ) : null}
            <label
              className='wb-radio wb-u-font-normal'
              htmlFor={`prompt-option-${step.cid}`}
            >
              <input
                className='wb-radio__input'
                data-action='step-row#changeStepType'
                data-step-row-client-type-param='PromptStep'
                data-step-row-step-cid-param={step.cid}
                data-step-row-target='promptStepInput'
                id={`prompt-option-${step.cid}`}
                name={`step-${step.cid}`}
                type='radio'
              />
              The approver enters the name and email when approving
            </label>
            {this.renderStepOptions()}
          </div>
        </fieldset>
        {this.renderApproverListAdder()}
        {this.renderRecipientAdder()}
      </React.Fragment>
    );
  }

  renderApproverListAdder(): JSX.Element | boolean {
    return (
      <div data-step-row-target='approverListAdderSection'>
        <h2 className='wb-heading-4 wb-heading--colored'>Recipients</h2>
        <ApproverListAdder />
      </div>
    );
  }

  renderRecipientAdder(): JSX.Element | boolean {
    const { step: { cid } } = this.props;

    return (
      <div data-step-row-target='recipientAdderSection'>
        <h2 className='wb-heading-4 wb-heading--colored'>Recipients</h2>
        <RecipientAdder stepCid={cid} />
        <div className='wb-u-margin-t-4'>
          {this.renderBulkRecipientAdder()}
        </div>
      </div>
    );
  }

  renderStepOptions(): JSX.Element {
    return (
      <React.Fragment>
        <div className='wb-u-margin-v-1'>
          <p className='wb-label'>Additional options</p>
        </div>
        <div>
          <label className='wb-checkbox wb-u-font-normal'>
            <input
              className='skippable wb-checkbox__input'
              data-step-row-target='skippable'
              name='routing_wizard[steps][][skippable]'
              type='checkbox'
              value='true'
            />
            This step can be skipped by the previous approver
          </label>
          <label className='wb-checkbox wb-u-font-normal'>
            <input
              className='special-approver wb-checkbox__input'
              data-step-row-target='specialApprover'
              name='routing_wizard[steps][][special_approver]'
              type='checkbox'
              value='true'
            />
            Approvers can edit fields
          </label>
        </div>
      </React.Fragment>
    );
  }

  renderBulkRecipientAdder(): JSX.Element | boolean {
    const { step } = this.props;

    return (
      <details className='recipient-bulk-adder'>
        <summary style={{ display: 'list-item' }}>Add recipients in bulk</summary>
        <div className='row wb-u-margin-v-4'>
          <div className='col-sm-6'>
            <label className='wb-label' htmlFor={`title-or-name-${step.cid}`}>
              Title or name
            </label>
            <textarea
              className='wb-textarea'
              data-step-row-target='bulkRecipientNames'
              id={`title-or-name-${step.cid}`}
              rows={10}
            />
          </div>
          <div className='col-sm-6'>
            <label className='wb-label' htmlFor={`emails-${step.cid}`}>
              Emails
            </label>
            <textarea
              className='wb-textarea'
              data-step-row-target='bulkRecipientEmails'
              id={`emails-${step.cid}`}
              rows={10}
            />
          </div>
        </div>
        <button
          className='wb-button wb-button--small wb-button--primary pull-right'
          data-action='step-row#bulkAddRecipients'
          data-step-row-step-cid-param={step.cid}
          type='button'
        >
          Add recipients to step
        </button>
      </details>
    );
  }
}

export default StepAdvancedOptionsRow;
export type { Props };
