// @ts-strict-ignore
import update from 'immutability-helper';

import createBasicReducer from 'src/common/basic_reducer';
import notify from 'src/helpers/notify';

const operations = {
  '@@INIT': initStore,
  'responses/SET': setResponses,
  'responses/SET_ALL_SELECTED_ACROSS_PAGES': setAllSelectedAcrossPages,
  'responses/SET_HEADERS': setHeaders,
  'responses/UPDATE': updateResponse,
  'responses/UPDATE_ALL': updateAllResponses,
  'responses/UPDATE_META': updateMeta,
};

function initStore() {
  return { meta: { } };
}

function setResponses(previousState: ResponsesState, responses) {
  return { ...previousState, responses };
}

function setAllSelectedAcrossPages(
  previousState: ResponsesState,
  areAllSelectedAcrossPages,
) {
  return { ...previousState, areAllSelectedAcrossPages };
}

function setHeaders(previousState: ResponsesState, headers) {
  return { ...previousState, headers };
}

function updateMeta(previousState: ResponsesState, meta) {
  return update(previousState, { meta: { $merge: meta } });
}

function updateAllResponses(previousState: ResponsesState, responseData) {
  if (!previousState.responses) {
    notify(
      'Trying to update all responses before loading',
      { previousState, responseData },
    );
    return previousState;
  }

  const newResponses = previousState.responses.map((response) => {
    return { ...response, ...responseData };
  });

  return { ...previousState, responses: newResponses };
}

function updateResponse(previousState: ResponsesState, responseData) {
  const newResponses = previousState.responses.slice();
  const oldResponse = newResponses.find((response) => {
    return response.id === responseData.id;
  });
  const newResponse = { ...oldResponse, ...responseData };

  newResponses[newResponses.indexOf(oldResponse)] = newResponse;

  return { ...previousState, responses: newResponses };
}

export default createBasicReducer(operations);
