// @ts-strict-ignore
import React from 'react';
import ReactDOM from 'react-dom';

import ContextMenuWrapper from 'src/doc_editor/context_menu_wrapper';
import SignatureContextMenu
  from 'src/doc_editor/fields/components/context_menus/signature';

class SignatureContextMenuWrapper extends ContextMenuWrapper {
  updateDisplay(opts): void {
    super.updateDisplay(opts);
    const { number, showSecurityMetadata } = opts;

    ReactDOM.render(
      <React.StrictMode>
        <SignatureContextMenu
          number={number}
          showSecurityMetadata={showSecurityMetadata}
          updateFields={this.updateFields}
        />
      </React.StrictMode>,
      this.fieldSpecificFooterContainer,
    );
  }
}

export default SignatureContextMenuWrapper;
