import React, { ReactNode } from 'react';

import Icon from 'src/common/components/icon';

type Props = {
  stepCid: string;
};

class RecipientAdder extends React.Component<Props, never> {
  render(): ReactNode {
    const { stepCid } = this.props;

    const titleId = `recipient-adder-title-label-${stepCid}`;
    const emailId = `recipient-adder-email-label-${stepCid}`;

    return (
      <React.Fragment>
        <p className='wb-text wb-text--muted wb-u-margin-b-0'>
          Enter the recipient(s) who the previous approver will select from at this
          step.
        </p>
        <table className='wb-table recipient-adder'>
          {renderTableHeaders(titleId, emailId)}
          <tbody data-step-row-target='recipientsContainer' />
        </table>
        <button
          className='btn btn-link text-uppercase wb-u-margin-b-4'
          data-action='step-row#addRecipient'
          data-step-row-step-cid-param={stepCid}
          type='button'
        >
          <span
            aria-hidden='true'
            className='wb-button__icon-left wb-button__icon--small'
          >
            <Icon name='plus' />
          </span>
          Add recipient
        </button>
      </React.Fragment>
    );
  }
}

// private

function renderTableHeaders(titleId: string, emailId: string): JSX.Element {
  return (
    <thead>
      <tr>
        <th id={titleId}>
          <p className='wb-label wb-label--required'>Title or Name</p>
        </th>
        <th id={emailId}>
          <p className='wb-label wb-label--required'>Email</p>
        </th>
      </tr>
    </thead>
  );
}

export default RecipientAdder;
export type { Props };
