import React from 'react';
import ReactDOM from 'react-dom';

import { findEl } from 'src/helpers/finders';

let storedMessages: StoredMessage[] = [];

function flash(type: FlashType, text: string): void {
  storedMessages = storedMessages.concat({ text, type });
  renderComponent();
}

namespace flash {
  export function reset(): void {
    storedMessages = [];
    renderComponent();
  }
}

function FlashComponent(props: { messages: StoredMessage[] }): JSX.Element {
  return <div>{messageDivs(props.messages)}</div>;
}

// private

function messageDivs(messages: StoredMessage[]): JSX.Element[] {
  return messages.map((message) => {
    const { type, text } = message;
    const alertType = type === 'error' ? 'alert-danger' : `alert-${type}`;

    return <div key={text} className={`app-alert alert ${alertType}`}>{text}</div>;
  });
}

function renderComponent(): void {
  ReactDOM.render(
    <React.StrictMode>
      <FlashComponent messages={storedMessages} />
    </React.StrictMode>,
    findEl(document.body, 'div', '#flash-container'),
  );
}

export default flash;
