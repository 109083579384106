import FieldModel from 'src/doc_editor/fields/models/field';

const ReferenceNumber = FieldModel.extend({
  defaults: {
    ...FieldModel.prototype.defaults,
    content: 'Reference #',
    required: false,
    startingReferenceNumber: '1',
  },
});

export default ReferenceNumber;
