import classNames from 'classnames';
import React from 'react';

type Props = {
  content: string;
  selected: boolean;
};

function Prefill(props: Props): JSX.Element {
  const { selected } = props;

  return (
    <span
      className={classNames('adjustable-font', 'field-container', { selected })}
    >
      {props.content || 'Group Data'}
    </span>
  );
}

export default Prefill;
