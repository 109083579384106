// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';

import { assertInstanceOf } from 'src/helpers/assertion';
import { onClick } from 'src/helpers/event';
import Location from 'src/helpers/location';
import { events, publish } from 'src/helpers/pub_sub';

class DragReorderListController extends Controller {
  draggedItem: HTMLElement;
  isChanged: boolean;

  connect(): void {
    this.draggedItem = null;
    this.isChanged = false;

    this.enableDraggableItem = this.enableDraggableItem.bind(this);
    this.dragStart = this.dragStart.bind(this);
    this.drag = this.drag.bind(this);
    this.dragEnd = this.dragEnd.bind(this);
    this.reorderList = this.reorderList.bind(this);
    this.deleteListItem = this.deleteListItem.bind(this);
    this.handleListItemAdded = this.handleListItemAdded.bind(this);

    document.addEventListener('submit', unmarkChanged);
    document.addEventListener('turbo:submit-start', unmarkChanged);

    this.element.addEventListener('draggableItemAdded', this.handleListItemAdded);

    Array.from(this.element.children).forEach(this.enableDraggableItem);
  }

  enableDraggableItem(listItem: EventTarget | Node): void {
    if (!assertInstanceOf(listItem, HTMLElement)) { return; }

    const dragHandle = listItem.querySelector('.drag-handle');

    dragHandle.addEventListener('mousedown', () => { setDraggable(listItem); });
    dragHandle.addEventListener('mouseup', () => { unsetDraggable(listItem); });

    listItem.addEventListener('dragstart', this.dragStart);
    listItem.addEventListener('drag', this.drag);
    listItem.addEventListener('dragend', this.dragEnd);

    listItem.addEventListener('dragenter', this.reorderList);

    listItem.addEventListener('dragover', (event) => { event.preventDefault(); });

    onClick(listItem, '.js-delete', this.deleteListItem);
  }

  dragStart(event: DragEvent): void {
    if (!assertInstanceOf(event.currentTarget, HTMLElement)) { return; }

    this.draggedItem = event.currentTarget;
  }

  drag(): void {
    this.draggedItem.classList.add('drag-active');
  }

  dragEnd(): void {
    unsetDraggable(this.draggedItem);
    this.draggedItem.classList.remove('drag-active');
    this.draggedItem = null;
    this.markChanged();
  }

  reorderList(event: DragEvent): void {
    if (this.draggedItem === null) { return; }
    if (!assertInstanceOf(event.currentTarget, HTMLElement)) { return; }

    const dragoverItem = event.currentTarget;

    if (dragoverItem === this.draggedItem) { return; }

    const { parentNode } = this.draggedItem;
    const listItems = Array.from(parentNode.children);
    const draggedIndex = listItems.indexOf(this.draggedItem);
    const dragoverIndex = listItems.indexOf(dragoverItem);

    if (draggedIndex < dragoverIndex) {
      parentNode.insertBefore(this.draggedItem, dragoverItem.nextSibling);
    } else {
      parentNode.insertBefore(this.draggedItem, dragoverItem);
    }

    this.refreshListItems();
  }

  handleListItemAdded(event: Event): void {
    this.enableDraggableItem(event.target);
    this.refreshListItems();
  }

  deleteListItem(event: Event): void {
    if (!assertInstanceOf(event.currentTarget, HTMLElement)) { return; }

    const listItem = event.currentTarget;

    listItem.remove();

    this.refreshListItems();
    this.markChanged();
  }

  refreshListItems(): void {
    const listItems = Array.from(this.element.children);

    listItems.forEach((listItem, index) => {
      const position: HTMLElement = listItem.querySelector('.js-position');

      if (position !== null && position !== undefined) {
        position.textContent = `${index + 1}`;
      }
    });
  }

  markChanged(): void {
    if (!this.isChanged) {
      this.isChanged = true;
      publish(events.DRAG_REORDER_LIST_CHANGED);
      Location.addBeforeUnload('drag-reorder-list');
    }
  }
}

function setDraggable(listItem: HTMLElement): void {
  listItem.setAttribute('draggable', 'true');
}

function unsetDraggable(listItem: HTMLElement): void {
  listItem.setAttribute('draggable', 'false');
}

function unmarkChanged(): void {
  Location.removeBeforeUnload('drag-reorder-list');
}

export default DragReorderListController;
