import FieldModel from 'src/doc_editor/fields/models/field';

const StaticText = FieldModel.extend({
  defaults: {
    ...FieldModel.prototype.defaults,
    allowedInNumberFormula: true,
    required: false,
  },

  validate() {
    return this.get('content') ? null : ['No content was entered into the input.'];
  },
});

export default StaticText;
