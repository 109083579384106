// @ts-strict-ignore
import 'src/extensions/jquery-ui/autocomplete';

// Time between keypress and when menu is shown
const SHOW_DELAY = 0;
const MAX_SUGGESTIONS = 4;

class Autofill {
  $elements: JQuery<HTMLInputElement | HTMLTextAreaElement>;
  elements: (HTMLInputElement | HTMLTextAreaElement)[];

  constructor(elements) {
    this.$elements = $(elements);
    this.elements = this.$elements.get();
    this.getSuggestions = this.getSuggestions.bind(this);

    if (this.$elements.length <= 0) { return; }

    this.$elements.autocomplete({
      delay: SHOW_DELAY,
      position: { within: document.body },
      source: (request, response) => {
        response(this.getSuggestions(request));
      },
    });
  }

  getSuggestions({ term }: { term: string }): string[] {
    const suggestions = new Set<string>();

    this.elements.forEach(({ value }) => {
      if (value.toLowerCase().startsWith(term.toLowerCase()) && value !== term) {
        suggestions.add(value);
      }
    });

    return Array.from(suggestions).slice(0, MAX_SUGGESTIONS);
  }
}

export default Autofill;
