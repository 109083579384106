// @ts-strict-ignore
import { InferThunkActionCreatorType } from 'react-redux';

import { fetchGet } from 'src/helpers/fetch';
import { stringifyValues } from 'src/helpers/json';
import notify from 'src/helpers/notify';
import { updateResponsesQueryParams as updateQueryParams }
  from 'src/query_params/action_creators';
import { headerMappings } from 'src/responses/helpers/header_mappings';

function setResponses(responses) {
  return { payload: responses, type: 'responses/SET' };
}

function setAllSelectedAcrossPages(areAllSelectedAcrossPages: boolean) {
  return {
    payload: areAllSelectedAcrossPages,
    type: 'responses/SET_ALL_SELECTED_ACROSS_PAGES',
  };
}

function setHeaders(headers) {
  return { payload: headers, type: 'responses/SET_HEADERS' };
}

function updateMeta(meta: ResponsesMeta) {
  return { payload: meta, type: 'responses/UPDATE_META' };
}

function fetchResponses(campaign: Campaign) {
  return function fetchResponsesAsync(dispatch, getState): void {
    const { queryParams } = getState().responses.meta;
    const responsesPath = `/campaigns/${campaign.id}/responses`;
    const stringifiedQueryParams = stringifyValues(queryParams);

    const fetchedAt = new Date().getTime();

    dispatch(updateMeta({ isFetching: true, lastFetchedAt: fetchedAt }));

    fetchGet(responsesPath, stringifiedQueryParams)
      .then(({ data: responses, meta }) => {
        if (fetchedAt !== getState().responses.meta.lastFetchedAt) { return; }
        dispatch(setResponses(responses));
        dispatch(setAllSelectedAcrossPages(false));
        dispatch(updateMeta({ isFetching: false, pagination: meta.pagination }));
        dispatch(setHeaders(
          headerMappings(campaign, queryParams.filters.statusGroup),
        ));
      }, notify);
  };
}

function updateResponse(payload) {
  return { payload, type: 'responses/UPDATE' };
}

function updateAllResponses(payload) {
  return { payload, type: 'responses/UPDATE_ALL' };
}

function applySearchTerm(searchTerm: string, campaign: Campaign) {
  return (dispatch, getState): void => {
    const { responses } = getState();
    const { filters } = responses.meta.queryParams;
    const queryParamsPayload: Partial<ResponsesQueryParams> =
      { filters, page: 1, searchTerm };
    const searchTermGiven = Boolean(searchTerm);

    dispatch(updateMeta({ isSearchActive: searchTermGiven }));
    dispatch(updateQueryParams(queryParamsPayload));
    dispatch(fetchResponses(campaign));
  };
}

export {
  setResponses,
  setAllSelectedAcrossPages,
  setHeaders,
  updateMeta,
  fetchResponses,
  updateResponse,
  updateAllResponses,
  applySearchTerm,
};

export type ApplySearchTerm = InferThunkActionCreatorType<typeof applySearchTerm>;
export type FetchResponses = InferThunkActionCreatorType<typeof fetchResponses>;
export type SetAllSelectedAcrossPages =
  InferThunkActionCreatorType<typeof setAllSelectedAcrossPages>;
export type UpdateAllResponses =
  InferThunkActionCreatorType<typeof updateAllResponses>;
export type UpdateMeta = InferThunkActionCreatorType<typeof updateMeta>;
export type UpdateQueryParams =
  InferThunkActionCreatorType<typeof updateQueryParams>;
export type UpdateResponse = InferThunkActionCreatorType<typeof updateResponse>;
