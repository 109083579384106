import React from 'react';

import { RemoveFlash } from 'src/flashes/action_creators';
import Flash from 'src/flashes/components/flashes/flash';

type Props = {
  flashes: FlashMessage[];
  removeFlash: RemoveFlash;
};

function Flashes(props: Props): JSX.Element {
  const { flashes, removeFlash } = props;

  return <div>{_flashes(flashes, removeFlash)}</div>;
}

// private

function _flashes(
  flashes: FlashMessage[],
  removeFlash: RemoveFlash,
): JSX.Element[] {
  return (
    flashes.map((flash) => {
      const { message, type, id } = flash;

      return (
        <Flash
          key={message}
          id={id}
          message={message}
          removeFlash={removeFlash}
          type={type}
        />
      );
    })
  );
}

export default Flashes;
export type { Props };
